/*eslint-disable*/
import React, { useState } from "react"
import Autocomplete from "react-google-autocomplete"
import PropTypes from "prop-types"

// nodejs library to set properties for components
// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import Input from "@material-ui/core/Input"

import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js"
import Button from "@material-ui/core/Button"
import Favorite from "@material-ui/icons/Favorite"

const useStyles = makeStyles(styles)

export default function CustomGooglePlace(props) {
  const [country, setCountry] = useState(["fr", "it", "mc", "be"])
  const classes = useStyles()
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,
    rtlActive,
  } = props

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
    [classes.labelRTL]: rtlActive,
  })
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  })
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  })
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  })
  var formControlClasses
  if (formControlProps !== undefined) {
    formControlClasses = classNames(formControlProps.className, classes.formControl)
  } else {
    formControlClasses = classes.formControl
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  })
  let newInputProps = {
    maxLength: inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
    minLength: inputProps && inputProps.minLength ? inputProps.minLength : undefined,
    step: inputProps && inputProps.step ? inputProps.step : undefined,
  }

  /**
   * Note send back imformations
   */
  const handleFeedBack = (_lat, _lng, _add, _city, _cp, _country) => {
    props.coord(_lat, _lng, _add, _city, _cp, _country)
  }

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      <Autocomplete
        className={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        style={{
          color: "#495057",
          opacity: 1,
          fontSize: 14,
          fontFamily: "Roboto",
          fontWeight: 400,
          lineHeight: 1.42857,
          border: 0,
          height: "unset",
          margin: 0,
          display: "block",
          padding: "6px 0 7px",
          minWidth: 0,
          background: "none",
          boxSizing: "content-box",
          animationName: "mui-auto-fill-cancel",
          animationDuration: "10ms",
          webkittaphighlightcolor: "transparent",
          left: 0,
          right: 0,
          bottom: 0,
          transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
          borderBottom: "2px solid #9c27b0",
        }}
        apiKey={"AIzaSyB302lH-t84KPb46kwuTBfPoIf67ezGD1w"}
        onPlaceSelected={(selected, a, c) => {
          // console.log(
          //   selected
          //   // selected.address_components[6].long_name,
          //   // selected.address_components[2].long_name
          // )

          let country = selected.address_components.find((item, index) => {
            if (item.types[0] === "country") {
              return true
            }
          })
          let locality = selected.address_components.find((item, index) => {
            if (item.types[0] === "locality") {
              return true
            }
          })
          let postal_code = selected.address_components.find((item, index) => {
            if (item.types[0] === "postal_code") {
              return true
            }
          })

          // console.log(locality, postal_code, country)

          handleFeedBack(
            selected.geometry.location.lat(),
            selected.geometry.location.lng(),
            selected.formatted_address, // Full adresse
            locality.long_name, // city
            postal_code.long_name, // CP
            country.long_name //Country
          )
        }}
        options={{
          types: ["geocode", "establishment"],
          componentRestrictions: { country },
        }}
        defaultValue={props?.value}
        placeholder="Commencez à saisir l'adresse ici..."
      />
      {helperText !== undefined ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  )
}

CustomGooglePlace.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
  rtlActive: PropTypes.bool,
}

/*eslint-disable*/
import React, { useEffect, useState } from "react"
import FormLabel from "@material-ui/core/FormLabel"
import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import { Divider } from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/core/styles"
import GridItem from "../../components/Grid/GridItem"

// Style
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js"

// Private Component
import { getFilter, setFilterMemo } from "../../services/LocaleStorage"

const useStylesExtended = makeStyles(stylesExtended)
const NAME = "dashboard"

export default ({ filter }) => {
  const classesExtended = useStylesExtended()
  const order = [
    { id: "az", name: "tri croissant par date de début" },
    { id: "za", name: "tri décroissant par date de début" },
    { id: "credesc", name: "tri décroissant par date de création" },
    { id: "creasc", name: "tri croissant par date de création" },
    // { id: "upddesc", name: "tri décroissant par date de modification" },
    // { id: "updasc", name: "tri croissant par date de modification" },
  ]
  const [state, setState] = useState({
    group: getFilter(NAME, "group"),
    running: getFilter(NAME, "running"),
    draft: getFilter(NAME, "draft"),
    order: getFilter(NAME, "sort"),
    last7: getFilter(NAME, "last7"),
  })

  /**
   * Note  changing State and sending new state to parent via props
   * @param _e
   */
  const handleChange = (_e) => {
    setState({ ...state, [_e.target.name]: _e.target.checked })
    filter({ ...state, [_e.target.name]: _e.target.checked })
    setFilterMemo(NAME, [_e.target.name], _e.target.checked)
  }

  /**
   *
   * @param _e
   */
  const handleOrder = (_e) => {
    setState({ ...state, [_e.target.name]: _e.target.value })
    filter({ ...state, [_e.target.name]: _e.target.value })
    setFilterMemo(NAME, "sort", _e.target.value)
  }

  return (
    <FormControl
      component="fieldset"
      style={{
        width: "100%",
        boxShadow: "1px 2px 9px #3f51b5",
        padding: 10,
        marginBottom: 50,
        borderRadius: 20,
        backgroundColor: "#13b9cd",
        color: "#ffffff",
      }}
    >
      <FormLabel
        component="legend"
        style={{ textAlign: "center", fontWeight: 800, color: "#ffffff" }}
      >
        Filtres
      </FormLabel>
      <FormGroup
        style={{
          flexDirection: "row",
          justifyContent: "space-around",
          color: "#ffffff",
        }}
      >
        <FormControlLabel
          style={{ color: "#ffffff" }}
          control={
            <Switch
              id="group"
              checked={state.group}
              onChange={handleChange}
              name="group"
              color="primary"
            />
          }
          label="Regrouper les événements identiques"
        />
        <FormControlLabel
          style={{ color: "#ffffff" }}
          control={
            <Switch
              id="running"
              checked={state.running}
              onChange={handleChange}
              name="running"
              color="primary"
            />
          }
          label="En cours ou à venir"
        />
        <FormControlLabel
          style={{ color: "#ffffff" }}
          control={
            <Switch
              id="draft"
              checked={state.draft}
              onChange={handleChange}
              name="draft"
              color="primary"
            />
          }
          label="Brouillon"
        />
        <FormControlLabel
          style={{ color: "#ffffff" }}
          control={
            <Switch
              id="last7"
              checked={state.last7}
              onChange={handleChange}
              name="last7"
              color="primary"
            />
          }
          label="7 derniers jours"
        />
      </FormGroup>
      <Divider
        style={{
          marginTop: 40,
          marginBottom: 40,
          backgroundColor: "#3f51b5",
          height: 3,
        }}
      />
      <FormGroup style={{ flexDirection: "row", justifyContent: "space-around" }}>
        <FormLabel
          component="legend"
          style={{ textAlign: "center", fontWeight: 800, color: "#ffffff" }}
        >
          Tris
        </FormLabel>
        <GridItem xs={12} sm={4}>
          <FormControl fullWidth className={classesExtended.selectFormControl}>
            <Select
              style={{ color: "#ffffff" }}
              value={state.order}
              onChange={handleOrder}
              MenuProps={{ className: classesExtended.selectMenu }}
              classes={{ select: classesExtended.select }}
              inputProps={{
                name: "order",
                id: "order",
              }}
            >
              {order.map((e, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={e.id}
                    classes={{
                      root: classesExtended.selectMenuItem,
                    }}
                  >
                    {e.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </GridItem>
      </FormGroup>
    </FormControl>
  )
}

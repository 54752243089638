import React, { useContext, useEffect, useState } from "react"
// react plugin for creating charts
import ChartistGraph from "react-chartist"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline"

// core components
import Heading from "components/Heading/Heading.js"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"

import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardIcon from "components/Card/CardIcon.js"
import CardBody from "components/Card/CardBody.js"
import CardFooter from "components/Card/CardFooter.js"

import {
  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  colouredLineChart,
  multipleBarsChart,
  colouredLinesChart,
  pieChart,
  eventByCompany,
} from "variables/charts.js"

import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js"

// Context & API
import {
  eventLastDays,
  eventLastMonths,
  runningEventByCompany,
} from "../../api/eventAction"
import authContext from "../../context/AuthContext"

const useStyles = makeStyles(styles)

export default function SummaryCharts() {
  const { mpId } = useContext(authContext)
  const [data7Days, setData7Days] = useState({})
  const [data12Months, setData12Months] = useState({})
  const [dataEbC, setDataEbC] = useState({})
  const classes = useStyles()

  /**
   * Note daily statistics
   */
  useEffect(() => {
    eventLastDays(mpId)
      .then((response) => {
        let st = response.data.data
        let label = []
        let count = []
        // console.log(response.data.data)
        // Data structure construction
        st.map((val, index) => {
          label.push(val.day)
          count.push(val.nb)
        })
        setData7Days({ labels: label, series: [count] })
      })
      .catch((e) => {
        console.log(e)
      })
  }, [mpId])

  /**
   * Note : Monthly statistics
   */
  useEffect(() => {
    eventLastMonths(mpId)
      .then((response) => {
        let st = response.data.data
        let label = []
        let count = []
        // console.log(response.data.data)
        // Data structure construction
        st.map((val, index) => {
          label.push(val.month)
          count.push(val.nb)
        })
        setData12Months({ labels: label, series: [count] })
      })
      .catch((e) => {
        console.log(e)
      })
  }, [mpId])

  /**
   * Note : Event By company
   */
  useEffect(() => {
    runningEventByCompany(mpId)
      .then((response) => {
        let st = response.data.data
        let label = []
        let count = []
        // Data structure construction
        st.map((val, index) => {
          label.push(val.name)
          count.push(val.nb)
        })
        setDataEbC({ labels: label, series: [count] })
      })
      .catch((e) => {
        console.log(e)
      })
  }, [mpId])

  return (
    <div>
      <Heading textAlign="center" title="Plus de détails" />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="rose">
              <ChartistGraph
                className="ct-chart-white-colors"
                data={data7Days} //{roundedLineChart.data}
                type="Line"
                options={roundedLineChart.options}
                listener={roundedLineChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Événements publiés</h4>
              <p className={classes.cardCategory}>
                Nombre d&apos;événement publié sur 7 jours
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="info">
              <ChartistGraph
                className="ct-chart-white-colors"
                data={data12Months} //{simpleBarChart.data}
                type="Bar"
                options={simpleBarChart.options}
                responsiveOptions={simpleBarChart.responsiveOptions}
                listener={simpleBarChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Événements publiés</h4>
              <p className={classes.cardCategory}>Répartition sur 12 mois</p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className={classes.ctLabels}
                data={dataEbC}
                type="Bar"
                options={eventByCompany.options}
                listener={eventByCompany.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Événements par établissement</h4>
              <p className={classes.cardCategory}>
                événements en cours de mes établissements
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

/*eslint-disable*/
import React, { useRef, useEffect, useState, useContext } from "react"
import { Link, useHistory } from "react-router-dom"

// Components
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import CardGroup from "react-bootstrap/CardGroup"
import Button from "react-bootstrap/Button"
import moment from "moment"
import "moment/locale/fr"
import { Badge } from "react-bootstrap"

// Private Component
import config from "../../config"
import { locales } from "../../utils/locale/MomentLocale"

// Styles
import "./eventScroller.styles.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { Edit } from "@material-ui/icons"

// API
import { eventList } from "../../api/eventAction"
import { removeHTMLTags } from "../../utils/functions"
import authContext from "../../context/AuthContext"
import FilterTools from "./FilterTools"

const MAX_POSSIBLE_HEIGHT = 390
// const BASE_URL_APP = "http://localhost:8000/public/events/"
const BASE_URL = config.BASE_URL

// SCROLL_MARGIN_BANG  valeur en pourcentage pour le calcul de la position de lancement de la requete de chargement de nouveaux éléments
const SCROLL_MARGIN_BANG = 20
let scrollEnd = false
let page = 1

const ExpendableText = ({
  maxHeight,
  children,
  IsXml,
  eid,
  cid,
  coords,
  groupNumber,
  visits = 0,
  group,
}) => {
  const history = useHistory()
  const ref = useRef()
  const [shouldShowExpand, setShouldShowExpand] = useState(false)
  const [expanded, setExpanded] = useState(true)

  useEffect(() => {
    if (ref.current.scrollHeight > maxHeight) {
      setShouldShowExpand(true)
      setExpanded(false)
    }
  }, [maxHeight])

  return (
    <Card.Body ref={ref} className="sp-card-body">
      <div
        className={` ${expanded ? "inner expanded" : "inner"}`}
        style={{ maxHeight: expanded ? MAX_POSSIBLE_HEIGHT : maxHeight }}
      >
        {children}
      </div>
      {shouldShowExpand && (
        <Button
          variant="outline-info"
          className="m-1"
          onClick={() => setExpanded(!expanded)}
          size="sm"
        >
          (...)
        </Button>
      )}
      <Button
        variant="outline-info"
        size="sm"
        className="m-1"
        target="_blank"
        href={`https://www.justnow.app/details/${eid}/0`}
      >
        Détails
      </Button>
      <Button variant="outline-info" size="sm" className="m-1">
        <Badge bg="info">{visits}</Badge>
      </Button>
      {groupNumber && (
        <Button variant="outline-info" size="sm" className="m-1">
          <Badge bg="secondary">{groupNumber}</Badge>
        </Button>
      )}
      {group === 0 && (
        <Button
          variant="outline-warning"
          size="sm"
          className="m-1"
          onClick={() => {
            const path = "/admin/event-manager"
            history.push({
              pathname: path,
              state: { evId: eid, cid: cid, from: "dashboard" },
            })
          }}
        >
          <Edit style={{ fontSize: 15 }} />
        </Button>
      )}
    </Card.Body>
  )
}

/**
 * Note :
 *      -> filterOptions 1 group by event, 0, null or undefined ignored
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function EventScroller(props) {
  const { mpId } = useContext(authContext)
  locales("fr")
  const [events, setEvents] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [coords, setCoords] = useState({})
  const [filterList, setFilterList] = useState(null)
  const [filterActive, setFilterActive] = useState(false)
  const [filterOptions, setFilterOptions] = useState({
    group: 1,
    running: 1,
    draft: 0,
    sort: "az",
    last7: 0,
  })

  useEffect(() => {
    if (props.coords)
      setCoords({
        lat: props.coords.lat,
        lng: props.coords.lng,
      })
  }, [props.coords])

  // useEffect(() => {
  //   // console.log("init scroller", filterOptions)
  //   loadEvents(
  //     filterOptions.running,
  //     filterOptions.group,
  //     filterOptions.draft,
  //     filterOptions.sort,
  //     filterOptions.last7
  //   )
  // }, [mpId])

  /**
   * Note : Managing FILTER
   */
  useEffect(() => {
    if (props.filterList) {
      setFilterList(props.filterList)
      const count = props.filterList.reduce(
        (nb, item) => (item.raised === true ? ++nb : nb),
        0
      )
      if (count && count > 0) {
        setFilterActive(true)
      } else setFilterActive(false)
    }
  }, [props.filterList])

  useEffect(() => {
    if (props.event) {
      let st = props.event
      // let eventSorting = st.slice(0)
      // eventSorting.sort(function(a,b) {
      //     return a.near - b.near
      // })
      setEvents(st)
    }
  }, [props.event])

  /**
   * Note :
   * if 0 then finished event
   * if 1 then active event (by date)
   * if 2 then all event (no filter)
   */
  const loadEvents = (
    _running = 1,
    _groupBySameEvent = 0,
    _draft = 0,
    _sortOption = "az",
    _last7 = 1
  ) => {
    // console.log("filter option", filterOptions)
    if (mpId)
      eventList(mpId, _running, _groupBySameEvent, _draft, _sortOption, _last7)
        .then((response) => {
          if (response.status === 200 && response.data.status === 1) {
            let st = response.data.data
            if (st.length > 0) {
              // console.log("st length", st.length, st)
              setEvents(st)
            } else setEvents([])
          }
        })
        .catch((e) => console.log(e))
  }

  /**
   * Note : change data state
   * @param _event
   */
  const handleChangeFilter = (_e) => {
    let run = _e.running === true ? 1 : 2
    let group = _e.group === true ? 1 : 0
    let draft = _e.draft === true ? 2 : 0
    let sort = _e.order ?? "az"
    let last7 = _e.last7 === true ? 1 : 0

    setFilterOptions({
      ...filterOptions,
      group: group,
      running: run,
      draft: draft,
      sort: sort,
      last7: last7,
    })
    loadEvents(run, group, draft, sort, last7)
  }

  const hasReachedBottom = (pos) => {
    return document.body.scrollHeight - document.body.offsetHeight >
      pos + (pos * SCROLL_MARGIN_BANG) / 100
      ? false
      : true
  }

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target
    let lastScrollY = window.scrollY

    if (hasReachedBottom(lastScrollY) === true && scrollEnd === false) {
      //setScrollEnd(true)
      scrollEnd = true
      // loadEvents(page)
    }
  }

  // const loadEvents = async (index) => {
  //   setLoading(true)
  //   showMixedEvents(index).then((response) => {
  //     if (response.data) {
  //       setEvents((prev) => [...prev, ...response.data])
  //       setLoading(false)
  //       scrollEnd = false
  //     }
  //   })
  //   page++
  // }

  return (
    // <div className='EventAdminScroller' onScroll={handleScroll}>
    <Container>
      <FilterTools filter={(a) => handleChangeFilter(a)} />
      <Row>
        {events &&
          events.map(function (event, index) {
            if (event.ev_id) {
              let bt = undefined
              if (filterList && filterActive)
                bt = filterList.find((item) => item.key === parseInt(event.btid))

              // Description HTML preparation & cleaning
              const regex = /(<([^>]+)>)/gi
              let htmlClx = event?.details?.replace(regex, "") //.substr(0, 100)
              htmlClx = htmlClx?.replace(/&nbsp;/g, "")
              htmlClx =
                htmlClx?.length > 160
                  ? htmlClx.substr(0, 260).trim() + "..."
                  : htmlClx

              let picUrl = ""

              if (event.cover)
                picUrl =
                  BASE_URL + "img/events/" + event.cover + "?" + event.updated_at
              else
                picUrl =
                  BASE_URL +
                  "img/events/cover-" +
                  event.ev_id +
                  ".jpg?" +
                  event.updated_at

              if ((filterActive && bt.raised === true) || !filterActive) {
                return (
                  <Col xs="12" sm="3" key={index}>
                    <CardGroup className="m-2 d-block sp-align">
                      <Card
                        className={`card-landing card-align-verticaly ${
                          event.is_draft === "1" ? "card-landing-draft" : ""
                        }`}
                      >
                        <div className="tester">
                          <Card.Img
                            src={picUrl}
                            className="img img-responsive sp-card-img"
                          />
                          <Card.ImgOverlay>
                            <Card.Title className="overlay-title">
                              {event.ev_name}
                            </Card.Title>
                          </Card.ImgOverlay>
                        </div>
                        <Card.Footer className="footerX">
                          <ExpendableText
                            maxHeight={50}
                            eid={event.ev_id}
                            cid={event.cid}
                            IsXml={event.is_xml}
                            groupNumber={
                              filterOptions.group === 1 ? event.nb : undefined
                            }
                            visits={event.visits}
                            group={filterOptions.group}
                          >
                            <p className="overlay-company-name">{event.c_name}</p>
                            <p>
                              {moment(event.dt_from).isSame(moment(), "day")
                                ? moment(event.dt_from).calendar()
                                : moment(event.dt_from).calendar()}{" "}
                              <br />
                            </p>
                            <p>{htmlClx}</p>
                          </ExpendableText>
                        </Card.Footer>
                        {moment(event.dt_to).isSame(moment(), "day") && (
                          <div className="ribbon ribbon-top-right">
                            <span>JustNow !</span>
                          </div>
                        )}
                      </Card>
                    </CardGroup>
                  </Col>
                )
              } else return null
            }
          })}
      </Row>
    </Container>
  )
}

export default EventScroller

import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { CheckoutForm } from "./StripeCheckout"

export const StripeContainer = () => {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: "{{CLIENT_SECRET}}",
  }

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  )
}

const stripePromise = loadStripe(
  "pk_test_51LVWLGFvHGc7tQKBy2xXbv9kqx2MfhGtspBpsaGTxtvuxUgmYrb8HjEiRBGIYV85FHkS8wxsqnETEuwLR5AdwsyC00d8zLKKTe"
)

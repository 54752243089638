import React, { useContext } from "react"
import { Redirect, Route } from "react-router-dom"
import Auth from "../context/AuthContext"

// eslint-disable-next-line react/prop-types
const AuthenticatedRoute = ({ path, component }) => {
  const { isAuthenticated } = useContext(Auth)

  return isAuthenticated ? (
    <Route path={path} component={component} />
  ) : (
    <Redirect to="/auth/login" />
  )
}

export default AuthenticatedRoute

import React from "react"
import ReactDOM from "react-dom"

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0"
import App from "./App"
import { BrowserRouter } from "react-router-dom"

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
)

import React from "react"
// nodejs library to set properties for components
import PropTypes from "prop-types"
import PhoneInput from "react-phone-number-input"
import fr from "react-phone-number-input/locale/fr.json"

// nodejs library that concatenates classes
import classNames from "classnames"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import FormControl from "@material-ui/core/FormControl"
import InputAdornment from "@material-ui/core/InputAdornment"

import InputLabel from "@material-ui/core/InputLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import Input from "@material-ui/core/Input"

// Style
import "react-phone-number-input/style.css"
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js"
import "./customPhoneInput.css"
const useStyles = makeStyles(styles)

export default function CustomPhoneInput(props) {
  const classes = useStyles()
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,
    rtlActive,
    value,
  } = props

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
    [classes.labelRTL]: rtlActive,
  })
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  })
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  })
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  })
  var formControlClasses
  if (formControlProps !== undefined) {
    formControlClasses = classNames(formControlProps.className, classes.formControl)
  } else {
    formControlClasses = classes.formControl
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  })
  let newInputProps = {
    maxLength: inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
    minLength: inputProps && inputProps.minLength ? inputProps.minLength : undefined,
    step: inputProps && inputProps.step ? inputProps.step : undefined,
  }

  const handleInputChange = (_phone) => {
    props.phoneChange(_phone)
  }

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {/*{labelText !== undefined ? (*/}
      {/*  <InputLabel*/}
      {/*    className={classes.labelRoot + " " + labelClasses}*/}
      {/*    htmlFor={id}*/}
      {/*    {...labelProps}*/}
      {/*  >*/}
      {/*    {labelText}*/}
      {/*  </InputLabel>*/}
      {/*) : null}*/}
      {/*<Input*/}
      {/*  classes={{*/}
      {/*    input: inputClasses,*/}
      {/*    root: marginTop,*/}
      {/*    disabled: classes.disabled,*/}
      {/*    underline: underlineClasses,*/}
      {/*  }}*/}
      {/*  id={id}*/}
      {/*  {...inputProps}*/}
      {/*  inputProps={newInputProps}*/}
      {/*/>*/}

      <PhoneInput
        labels={fr}
        className="phoneIt"
        style={{
          color: "#495057",
          opacity: 1,
          fontSize: 14,
          fontFamily: "Roboto",
          fontWeight: 400,
          lineHeight: 1.42857,
          border: "none",
          height: "unset",
          margin: 0,
          display: "flex",
          padding: "6px 0 7px",
          minWidth: 0,
          background: "none",
          boxSizing: "content-box",
          animationName: "mui-auto-fill-cancel",
          animationDuration: "10ms",
          webkittaphighlightcolor: "transparent",
          left: 0,
          right: 0,
          bottom: 0,
          transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        }}
        international
        defaultCountry="FR"
        countries={[
          "FR",
          "IT",
          "MC",
          "LU",
          "BE",
          "RU",
          "ES",
          "US",
          "CN",
          "GB",
          "CH",
          "DE",
          "CA",
        ]}
        countryCallingCodeEditable={false}
        placeholder="Téléphone"
        value={value}
        onChange={(e) => {
          handleInputChange(e, "phone")
        }}
      />
      {/*{helperText !== undefined ? (*/}
      {/*  <FormHelperText id={id + "-text"} className={helpTextClasses}>*/}
      {/*    {helperText}*/}
      {/*  </FormHelperText>*/}
      {/*) : null}*/}
    </FormControl>
  )
}

CustomPhoneInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
  rtlActive: PropTypes.bool,
  phoneChange: PropTypes.func,
  value: PropTypes.string,
}

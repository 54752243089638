import React, { useEffect, useState } from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Button from "components/CustomButtons/Button.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardAvatar from "components/Card/CardAvatar.js"
import CardFooter from "components/Card/CardFooter.js"

import avatar from "assets/img/icons/j_original.png"
//"assets/img/faces/avatar.jpg"

import styles from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js"

const useStyles = makeStyles(styles)

export default function LockScreenPage() {
  const [cardAnimaton, setCardAnimation] = useState("cardHidden")
  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("")
    }, 700)
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id)
    }
  })
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <form>
        <Card
          profile
          className={classes.customCardClass + " " + classes[cardAnimaton]}
        >
          <CardAvatar profile className={classes.cardAvatar}>
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              <img src={avatar} alt="..." />
            </a>
          </CardAvatar>
          <CardBody profile>
            <h4 className={classes.cardTitle}>Sécurité justNow</h4>
            <CustomInput
              labelText="Votre mot de passe"
              id="company-disabled"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                type: "password",
                autoComplete: "off",
              }}
            />
          </CardBody>
          <CardFooter className={classes.justifyContentCenter}>
            <Button color="rose" round>
              Déverrouiller
            </Button>
          </CardFooter>
        </Card>
      </form>
    </div>
  )
}

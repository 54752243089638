export function removeItem(itemToRemove) {
  window.localStorage.removeItem(itemToRemove)
}

export function getItem(item) {
  return window.localStorage.getItem(item)
}

export function addItem(localeStorageName, newItem) {
  window.localStorage.setItem(localeStorageName, newItem)
}

/**
 * Note : Saving all the choice of user
 * @param _localeStorageSubName
 * @param _value
 */
export function setErgonomyItem(_localeStorageSubName, _value) {
  // We check item existence
  const exist = window.localStorage.getItem("ergo")

  // Object structure for initial use (or deletion of local storage) with default value
  const objectStruct = {
    menuIsBackgroundImage: true,
    menuImage: null,
    menuColor: "black",
    menuItemColor: "green",
  }

  if (exist) {
    // We retrieve the item
    let ergo = JSON.parse(window.localStorage.getItem("ergo"))
    ergo[_localeStorageSubName] = _value
    window.localStorage.setItem("ergo", JSON.stringify(ergo))
  } else {
    // updating with new value
    objectStruct[_localeStorageSubName] = _value
    // We create the storage
    window.localStorage.setItem("ergo", JSON.stringify(objectStruct))
  }
}

/**
 * Note : ergo is an object stored so we use jSON parse
 * @returns {any}
 */
export function getErgo() {
  return JSON.parse(window.localStorage.getItem("ergo"))
}

/**
 * #########################################################################
 * #########################################################################
 *  Filter Memo
 * #########################################################################
 * #########################################################################
 */

/**
 * Note : Saving all the choice of user
 * @param _localeStorageSubName
 * @param _value
 */
export function setFilterMemo(_where, _localeStorageSubName, _value) {
  if (_value == null) return false
  // We check item existence
  const exist = window.localStorage.getItem("filter")

  // Object structure for initial use (or deletion of local storage) with default value
  const objectStruct = {
    event: {
      page: 0,
      itemNb: 10,
      ev_name: "",
      c_name: "",
    },
    company: {
      page: 0,
      itemNb: 10,
    },
    dashboard: {
      group: true,
      running: true,
      draft: false,
      sort: "az",
      last7: true,
    },
  }

  if (exist) {
    // We retrieve the item
    let filter = JSON.parse(window.localStorage.getItem("filter"))
    filter[_where][_localeStorageSubName] = _value
    window.localStorage.setItem("filter", JSON.stringify(filter))
  } else {
    // updating with new value
    objectStruct[_where][_localeStorageSubName] = _value
    // We create the storage
    window.localStorage.setItem("filter", JSON.stringify(objectStruct))
  }
}

/**
 * Note : ergo is an object stored so we use jSON parse
 * @returns {any}
 */
export function getFilter(_screenName, _itemName) {
  const exist = window.localStorage.getItem("filter")
  if (exist) {
    let filter = JSON.parse(window.localStorage.getItem("filter"))
    return filter[_screenName][_itemName] ?? null
  } else return null
}

/**
 *Note : Directly remove local storage "filter"
 */
export function removeFilter() {
  window.localStorage.removeItem("filter")
}

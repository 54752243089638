import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import InputAdornment from "@material-ui/core/InputAdornment"
import Icon from "@material-ui/core/Icon"

// @material-ui/icons
import Face from "@material-ui/icons/Face"
import Email from "@material-ui/icons/Email"
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardHeader from "components/Card/CardHeader.js"
import CardFooter from "components/Card/CardFooter.js"

// Private Component
import JAlert from "../../components/smallComponent/jAlert"

// Context & Api
import AuthContext from "../../context/AuthContext"
import { hasAuthenticated, login } from "../../services/AuthApi"

// Styling
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js"

const useStyles = makeStyles(styles)

export default function LoginPage() {
  const { isAuthenticated, setIsAuthenticated, mpId, setMpId } = useContext(
    AuthContext
  )
  const [cardAnimaton, setCardAnimation] = useState("cardHidden")
  const [data, setData] = useState({ email: undefined, password: undefined })
  const [showAlert, setShowAlert] = useState(false)
  let history = useHistory()

  useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("")
    }, 700)
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id)
    }
  }, [])

  useEffect(() => {
    if (hasAuthenticated()) {
      history.replace("/admin/dashboard")
    }
  }, [history, isAuthenticated])

  const handleInputChange = ({ currentTarget }) => {
    const { name, value } = currentTarget
    setData({ ...data, [name]: value })
  }

  /**
   * Note : changement of state manage by useeffect
   * @returns {Promise<void>}
   */
  const handleSubmit = async () => {
    try {
      login(data).then((response) => {
        if (response === false) setShowAlert(true)
        else {
          console.log("login RESPONSE", response)
          setMpId(response)
          setIsAuthenticated(response ? true : false)
          history.replace("/admin/dashboard")
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  const showInfo = () => {
    setShowAlert(!showAlert)
  }

  const classes = useStyles()
  return (
    <div className={classes.container}>
      <JAlert type="warning" show={showAlert} localStatus={showInfo} />
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form>
            <Card login className={classes[cardAnimaton]}>
              {/*background: linear-gradient(60deg, #4045ec, #d8a31b);*/}
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter} ${classes.cardHeaderContainer}`}
                // className={classes.cardHeaderContainer}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Connexion</h4>
                {/*                <div className={classes.socialLine}>
                  {["fab fa-facebook", "fab fa-google"].map((prop, key) => {
                    return (
                      <Button
                        color="transparent"
                        justIcon
                        key={key}
                        className={classes.customButtonClass}
                      >
                        <i className={prop} />
                      </Button>
                    )
                  })}
                </div>*/}
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "email",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    onChange: handleInputChange,
                  }}
                />
                <CustomInput
                  labelText="Mot de passe"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    onChange: handleInputChange,
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="rose" simple size="lg" block onClick={handleSubmit}>
                  Connexion
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  )
}

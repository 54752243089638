import { createContext } from "react"

export default createContext({
  isAuthenticated: false,
  setIsAuthenticated: (value) => {},
  mpId: undefined,
  setMpId: (value) => {},
  mpEmail: undefined,
  setMpEmail: (value) => {},
})

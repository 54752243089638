/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from "react"
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert"
// material-ui components
import { makeStyles } from "@material-ui/core/styles"

// styles for buttons & text on sweetalert
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js"
import typographyStyle from "../../assets/jss/material-dashboard-pro-react/components/typographyStyle"

const useStyles = makeStyles(styles)
const useTypoStyles = makeStyles(typographyStyle)

const label = {
  titleKO: "Vos identifiants ne sont pas valides",
  subTitleKO: ["Veuillez essayer à nouveau"],
  btnOk: "OK",
}

export default function JAlert({
  type,
  show,
  localStatus,
  feedBack,
  titleText = label.titleKO, // for legacy todo: deprecate this passing the value
  subTitleText = label.titleKO, // for legacy todo: deprecate this passing the value
}) {
  const classes = useStyles()
  const typo = useTypoStyles()
  const [alert, setAlert] = useState(undefined)

  useEffect(() => {
    // console.log(
    //   "alert info ",
    //   alert,
    //   show,
    //   subTitleText,
    //   Array.isArray(subTitleText)
    // )
    if (show) setAlert(show)
  }, [show])

  const hideAlert = () => {
    setAlert(false)
    localStatus(false)
    if (feedBack) feedBack()
  }

  const SuccessAlert = () => {
    return (
      <SweetAlert
        warning={type === "warning"}
        success={type === "success"}
        style={{ display: "block", marginTop: "-100px" }}
        customClass={type === "warning" ? typo.warningText : typo.successText}
        title={titleText}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        {subTitleText && Array.isArray(subTitleText)
          ? subTitleText.map((e, k) => {
              return (
                <p key={k} style={{ textAlign: "left" }}>
                  {e}
                </p>
              )
            })
          : null}
      </SweetAlert>
    )
  }
  return <div>{alert && <SuccessAlert />}</div>
}

import axios from "axios"
import config from "../config"
import { getItem } from "../services/LocaleStorage"

const BASE_URL = config.BASE_URL
const apiX = axios.create({
  baseURL: BASE_URL,
})

export const getPlans = () => {
  return apiX.get(BASE_URL + `api/multipro/plans`)
}

export const getActivePlan = (_mpId) => {
  const param = {
    mpid: _mpId,
  }

  return apiX.post(BASE_URL + `api/multipro/activeplan`, param)
}

export const getUrlPortalIfSubscriptionExist = (_mpId) => {
  const param = {
    mpid: _mpId,
  }

  return apiX.post(BASE_URL + `api/stripe/portal`, param)
}

import React, { useEffect, useState } from "react"
import {
  Router,
  Redirect,
  Route,
  Switch,
  BrowserRouter,
  useLocation,
  useHistory,
} from "react-router-dom"
import { transitions, positions, Provider as AlertProvider } from "react-alert"
import "moment/locale/fr"

// Template Component
import RtlLayout from "./layouts/RTL"
import AuthLayout from "./layouts/Auth"
import AdminLayout from "./layouts/Admin"
import AlertTemplate from "react-alert-template-basic"

// Private Component
import { userContext } from "../src/context/userContext"
import {
  getMpEmailFromLocalStorage,
  getMpIdFromLocalStorage,
  hasAuthenticated,
} from "./services/AuthApi"
import Auth from "./context/AuthContext"
import AuthenticatedRoute from "./components/AuthenticatedRoute"
import { removeItem } from "./services/LocaleStorage"
import AuthContext from "./context/AuthContext"
import { Account } from "./views/Account/Account"

// Constants
const LOGIN_BASE_PATH = "/auth/"
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "10px",
  width: "400px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(hasAuthenticated())
  const [mpId, setMpId] = useState(getMpIdFromLocalStorage())
  const [mpEmail, setMpEmail] = useState(getMpEmailFromLocalStorage())
  let history = useHistory()
  let location = useLocation()

  /**
   * Note check login status / token cookies for each screen
   */
  useEffect(() => {
    if (
      location &&
      hasAuthenticated() === false &&
      !location.pathname.includes(LOGIN_BASE_PATH)
    ) {
      removeItem("jnlock")
      history.replace("/auth/login")
    }
  }, [location])
  /**
   * Note :
   */

  return (
    <Auth.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        mpId,
        setMpId,
        mpEmail,
        setMpEmail,
      }}
    >
      <AlertProvider template={AlertTemplate} {...options}>
        <Switch>
          <Route path="/rtl" component={RtlLayout} />
          <Route path="/auth" component={AuthLayout} />
          {/*<Route path="/admin" component={AdminLayout} />*/}
          <AuthenticatedRoute path="/admin" component={AdminLayout} />
          {/*<AuthenticatedRoute path="/admin/account" component={Account} />*/}
          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </AlertProvider>
    </Auth.Provider>
  )
}

export default App

import React, { useContext, useEffect, useState } from "react"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Heading from "components/Heading/Heading.js"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"

import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardIcon from "components/Card/CardIcon.js"
import CardFooter from "components/Card/CardFooter.js"

import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js"
import Icon from "@material-ui/core/Icon"
import Danger from "../components/Typography/Danger"
import Warning from "@material-ui/icons/Warning"
import Store from "@material-ui/icons/Store"
import DateRange from "@material-ui/icons/DateRange"
import LocalOffer from "@material-ui/icons/LocalOffer"
import Update from "@material-ui/icons/Update"

// Context & API
import { companyCount } from "../api/companyAction"
import authContext from "../context/AuthContext"
import { eventCount } from "../api/eventAction"

const label = {
  company: "Établissement",
  toCheck: "À contrôler",
  event: "Événements",
}

const useStyles = makeStyles(styles)

export default function AccountStatSummary() {
  const classes = useStyles()
  const { mpId } = useContext(authContext)
  const [stat, setStat] = useState({
    nbCompany: 0,
    nbSuspended: 0,
    nbEvent: 0,
  })

  /**
   * Note récupération des données statistiques (company=
   */
  useEffect(() => {
    if (mpId)
      companyCount(mpId)
        .then((response) => {
          let st = response.data.data[0]
          if (st)
            setStat((prevState) => {
              return { ...prevState, nbCompany: st.nb, nbSuspended: st.nb_suspended }
            })
        })
        .catch((e) => {
          console.log(e)
        })
  }, [mpId])

  /**
   * Note récupération des données statistiques (company=
   */
  useEffect(() => {
    if (mpId)
      eventCount(mpId)
        .then((response) => {
          let st = response.data.data[0].nb
          if (st)
            setStat((prevState) => {
              return { ...prevState, nbEvent: st }
            })
        })
        .catch((e) => {
          console.log(e)
        })
  }, [mpId])

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>{label.company}</p>
              <h3 className={classes.cardTitle}>{stat.nbCompany}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Vous gérez {stat.nbCompany} établissments
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>À contrôler</p>
              <h3 className={classes.cardTitle}>{stat.nbSuspended}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <LocalOffer />
                {stat.nbSuspended} établissements sont suspendus
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={4}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>star</Icon>
                {/*<i className="fab fa-twitter" />*/}
              </CardIcon>
              <p className={classes.cardCategory}>{label.event}</p>
              <h3 className={classes.cardTitle}>{stat.nbEvent}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Données à jour
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}

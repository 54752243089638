import React, { useEffect, useState } from "react"
import HyperModal from "react-hyper-modal"
import PropTypes from "prop-types"
import "react-image-crop/dist/ReactCrop.css"

// Private Component
import Crop from "../Crop"

import "./cropperX.css"
import { makeStyles } from "@material-ui/core/styles"

const overrideStyle = {
  modalFullScreen: {
    width: "99%",
    height: "99%",
  },
}
const useStyles = makeStyles(overrideStyle)

const CropperX = ({
  visibility,
  backInfo,
  title,
  image,
  b64,
  direction,
  cropAreaAspect = 2 / 3,
}) => {
  const [visible, setVisible] = useState(visibility)

  useEffect(() => {
    if (visibility && typeof visibility == "boolean") {
      setVisible(visibility)
    }
  }, [visibility])

  const openModal = () => setVisible(true)

  const closeModal = () => {
    backInfo()
    setVisible(false)
  }

  const classes = useStyles()
  return (
    <HyperModal isFullscreen isOpen={visible} requestClose={closeModal}>
      <Crop
        title={title}
        image={image}
        b64={b64}
        direction={direction}
        exit={closeModal}
        cropAreaAspect={cropAreaAspect}
      />
    </HyperModal>
  )
}

CropperX.propTypes = {
  visibility: PropTypes.bool,
  backInfo: PropTypes.func,
  title: PropTypes.string,
  image: PropTypes.string,
  b64: PropTypes.func,
  direction: PropTypes.string,
  cropAreaAspect: PropTypes.number,
}
export default CropperX

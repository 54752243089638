/*eslint-disable*/
import React, { useState, useCallback, useRef, useEffect } from "react"
import Cropper from "react-easy-crop"
import GridContainer from "../Grid/GridContainer"
import GridItem from "../Grid/GridItem"

// Private Component
import { getCroppedImg } from "./utils/cropImage"
import Button from "../CustomButtons/Button"

// Style
import "./styles.css"
import Favorite from "@material-ui/icons/Favorite"

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
}
const CROP_AREA_ASPECT = 2 / 3

export const Crop = ({ title, image, b64, direction, exit, cropAreaAspect }) => {
  const [src, setSrc] = useState(undefined)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [aspect, setAspect] = useState(undefined)
  const [rotation, setRotation] = useState(0)
  const [showZoomHint, setShowZoomHint] = useState(false)
  const [showMultiTouchHint, setShowMultiTouchHint] = useState(false)
  const [removeTouchAction, setRemoveTouchAction] = useState(false)
  const [croppedArea, setCroppedArea] = useState(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const zoomTimeoutRef = useRef()
  const touchTimeoutRef = useRef()
  let fileInput = React.createRef()

  useEffect(() => {
    if (cropAreaAspect) setAspect(cropAreaAspect)
  }, [cropAreaAspect])

  useEffect(() => {
    if (image) setSrc(image)
  }, [image])

  useEffect(() => {
    clearTimeout(zoomTimeoutRef.current)
    clearTimeout(touchTimeoutRef.current)
  }, [])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(src, croppedAreaPixels, rotation)
      //await convertFile(croppedImage)
      getBase64CroppedImage(croppedImage)
      setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [src, croppedAreaPixels, rotation])

  /**
   * NOte : Waiting for  base 64 data
   * @returns {Promise<void>}
   */
  const getBase64CroppedImage = async (_croppedImage) => {
    //const croppedImage = await getCroppedImg(src, croppedAreaPixels, rotation)
    let st = await convertFile(_croppedImage)
    b64(st)
    return st
  }

  const onWheelRequest = (e) => {
    // require the CTRL/⌘ key to be able to zoom with wheel
    if (e.ctrlKey || e.metaKey) {
      setShowZoomHint(false)
      return true
    }
    setShowZoomHint(true)
    clearTimeout(zoomTimeoutRef.current)
    zoomTimeoutRef.current = setTimeout(() => setShowZoomHint(false), 2000)
    return false
  }
  const onTouchRequest = (e) => {
    // require 2 fingers to be able to interact with the image
    if (e.touches.length > 1) {
      setShowMultiTouchHint(false)
      setRemoveTouchAction(true)
      return true
    }
    setShowMultiTouchHint(true)
    setRemoveTouchAction(false)
    clearTimeout(touchTimeoutRef.current)
    touchTimeoutRef.current = setTimeout(() => setShowMultiTouchHint(false), 2000)
    return false
  }

  //"https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000"

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener("load", () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

  function convertFile(file) {
    return new Promise((resolve) => {
      let fileInfo
      let baseURL = ""
      const reader = new FileReader()
      // Convert the file to base64 text
      reader.readAsDataURL(file)
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        //console.log("Called", reader)
        baseURL = reader.result
        //console.log(baseURL)
        resolve(baseURL)
      }
      console.log(fileInfo)
    })
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      let imageDataUrl = await readFile(file)

      // apply rotation if needed
      // const orientation = await getOrientation(file)
      // const rotation = ORIENTATION_TO_ANGLE[orientation]
      // if (rotation) {
      //   imageDataUrl = await getRotatedImage(imageDataUrl, rotation)
      // }

      setSrc(imageDataUrl)
    }
  }

  const handleClick = () => {
    fileInput.current.click()
  }

  const Output = ({ croppedArea, image, aspect }) => {
    const scale = 100 / croppedArea.width
    const transform = {
      x: `${-croppedArea.x * scale}%`,
      y: `${-croppedArea.y * scale}%`,
      scale,
      width: "calc(100% + 0.5px)",
      height: "auto",
    }
    const imageStyle = {
      transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
      width: transform.width,
      height: transform.height,
    }

    return (
      <div className="output" style={{ paddingBottom: `${100 / aspect}%` }}>
        <img src={image} alt="" style={imageStyle} />
      </div>
    )
  }

  return (
    <div className="Crop">
      <legend>{title}</legend>
      <GridContainer direction={direction}>
        <GridItem xs={12} sm={8}>
          <div className="crop-container">
            <Cropper
              image={src}
              crop={crop}
              zoom={zoom}
              aspect={aspect}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onCropAreaChange={(croppedArea) => {
                setCroppedArea(croppedArea)
              }}
              onZoomChange={setZoom}
              onWheelRequest={onWheelRequest}
              onTouchRequest={onTouchRequest}
              classes={
                removeTouchAction && { containerClassName: "removeTouchAction" }
              }
            />
            {showZoomHint && (
              <div className="zoom-hint">
                <p>Utiliser ⌘ + scroll (ou ctrl + scroll) pour zoomer</p>
              </div>
            )}
            {showMultiTouchHint && (
              <div className="touch-hint">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <p>Utiliser vos doigts pour interagir avec l'image</p>
              </div>
            )}
          </div>
          <div className="controls">
            <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value)
              }}
              className="zoom-range"
            />
          </div>
          <input
            id="selectFile"
            type="file"
            onChange={onFileChange}
            accept="image/*"
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          <div className="viewer">
            <div>
              {/*<p style={{ color: "black" }}>{croppedArea && croppedArea}</p>*/}
              {/*<p style={{ color: "black" }}>--->{aspect}</p>*/}
              {croppedArea && aspect ? (
                <Output croppedArea={croppedArea} image={src} aspect={aspect} />
              ) : null}
            </div>
          </div>
        </GridItem>
        <GridItem xs={12} sm={6} className="validate">
          <Button
            color="warning"
            round
            onClick={() => {
              exit()
            }}
          >
            {`Sortir de l'éditeur d'image sans enregistrer`}
          </Button>
        </GridItem>
        <GridItem xs={12} sm={6} className="validate">
          <Button
            color="success"
            round
            onClick={() => {
              showCroppedImage()
              exit()
            }}
          >
            Valider mon image
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  )
}

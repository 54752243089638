import React, { useEffect, useState } from "react"
// used for making the prop types of this component
import PropTypes from "prop-types"

// core components
import Button from "components/CustomButtons/Button.js"

import defaultImage from "assets/img/image_placeholder.jpg"
import defaultAvatar from "assets/img/placeholder.jpg"

export default function ImageUpload(props) {
  const [file, setFile] = useState(null)
  const [imagePreviewUrl, setImagePreviewUrl] = useState(
    props.avatar ? defaultAvatar : defaultImage
  )
  const [uri, setUri] = useState(undefined)

  useEffect(() => {
    setUri(props.url)
  }, [props.url])

  let fileInput = React.createRef()

  /**
   * Note : upload new image
   * @param e
   */
  const handleImageChange = async (e) => {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onloadend = () => {
      setFile(file)
      setImagePreviewUrl(reader.result)
      console.log(file)
    }
    if (file) {
      setUri(undefined)
      reader.readAsDataURL(file)
      let st = await convertFile(file)
      props.b64(st)
      console.log(st)
    }
  }

  const convertFile = (file) => {
    return new Promise((resolve) => {
      let fileInfo
      let baseURL = ""
      const reader = new FileReader()
      // Convert the file to base64 text
      reader.readAsDataURL(file)
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        //console.log("Called", reader)
        baseURL = reader.result
        //console.log(baseURL)
        resolve(baseURL)
      }
    })
  }

  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault()
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  }
  const handleClick = () => {
    fileInput.current.click()
  }
  const handleRemove = () => {
    setFile(null)
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage)
    fileInput.current.value = null
  }
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
        {props?.modification === true ? (
          <img src={uri ? uri : imagePreviewUrl} alt="..." />
        ) : (
          <img src={uri ? uri : imagePreviewUrl} alt="..." />
        )}
        {/*<img src={imagePreviewUrl} alt="..." />*/}
      </div>
      {props?.modification === true ? (
        <div>
          {file === null ? (
            <Button {...addButtonProps} onClick={() => handleClick()}>
              {avatar ? "Ajouter" : "Choisir"}
            </Button>
          ) : (
            <span>
              <Button {...changeButtonProps} onClick={() => handleClick()}>
                Changer
              </Button>
              {avatar ? <br /> : null}
              <Button {...removeButtonProps} onClick={() => handleRemove()}>
                <i className="fas fa-times" /> Supprimer
              </Button>
            </span>
          )}
        </div>
      ) : null}
    </div>
  )
}

ImageUpload.propTypes = {
  b64: PropTypes.func,
  avatar: PropTypes.bool,
  modification: PropTypes.bool,
  url: PropTypes.string,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
}

export const translations = {
  uploadStep: {
    title: "Importer un fichier",
    manifestTitle: "Les données que nous attendons:",
    manifestDescription:
      "(Vous pourrez renomer ou supprimer des champs à l'étape suivante)",
    dropzone: {
      title: "Télécharger des fichiers de type .xlsx, .xls or .csv ",
      errorToastDescription: "upload rejeté",
      activeDropzoneTitle: "Déposé votre fichier ici...",
      buttonTitle: "Choisir un fichier",
      loadingTitle: "Traitement...",
    },
    selectSheet: {
      title: "Sélectionnez la feuille à utiliser",
      nextButtonTitle: "Suivant",
    },
  },
  selectHeaderStep: {
    title: "Choisir les entêtes de colonne",
    nextButtonTitle: "Suivant",
  },
  matchColumnsStep: {
    title: "Correspondance des colonnes",
    nextButtonTitle: "Suivant",
    userTableTitle: "Vos données",
    templateTitle: "Deviendront",
    selectPlaceholder: "Choisir...",
    ignoredColumnText: "Colonne ignorée",
    subSelectPlaceholder: "Choisir...",
    matchDropdownTitle: "Match",
    unmatched: "Incomparable",
    duplicateColumnWarningTitle: "Another column unselected",
    duplicateColumnWarningDescription: "Columns cannot duplicate",
  },
  validationStep: {
    title: "Valider vos données compatible justNow",
    nextButtonTitle: "Confirmer",
    noRowsMessage: "Aucune donnée",
    noRowsMessageWhenFiltered: "Aucune donnée contenant des erreurs",
    discardButtonTitle: "Ignorer les lignes sélectionnées",
    filterSwitchTitle: "Afficher uniquement les lignes avec des erreurs",
  },
  alerts: {
    confirmClose: {
      headerTitle: "Quitter l'import",
      bodyText: "êtes vous sûr? Vos données ne seront pas sauvegardées.",
      cancelButtonTitle: "Annuler",
      exitButtonTitle: "Quitter",
    },
    submitIncomplete: {
      headerTitle: "Il y a des erreurs",
      bodyText:
        "Certaines lignes contiennent encore des erreurs. Les lignes contenant des erreurs seront ignorées lors de la soumission.",
      bodyTextSubmitForbidden: "Il y a toujours des lignes contenant des erreurs.",
      cancelButtonTitle: "Annuler",
      finishButtonTitle: "Confirmer",
    },
    unmatchedRequiredFields: {
      headerTitle: "Il y a des colonnes sans correspondance",
      bodyText:
        "Il y a des colonnes requises qui n'ont pas de correspondance ou ont été ignoré. Voulez-vous continuer?",
      listTitle: "Colonnes sans correspondance:",
      cancelButtonTitle: "Annuler",
      continueButtonTitle: "Continue",
    },
    toast: {
      error: "Erreur",
    },
  },
}

/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import HyperModal from "react-hyper-modal"

import moment from "moment"
import MomentUtils from "@date-io/moment"
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import { GridLoader } from "react-spinners"

// Private Component
import {
  eventDetails,
  notificationByEmailOnEventActionByMultipro,
  openClosedCompanyListByMultiproId,
  updateEvent,
  uploadNewEvent,
} from "../../api/eventAction"
import config from "../../config"
import { companyList, companyListThatCanPublish } from "../../api/companyAction"
import JAlert from "../../components/smallComponent/jAlert"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import FormControlLabel from "@material-ui/core/FormControlLabel"

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Switch from "@material-ui/core/Switch"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"

// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite"
import Store from "@material-ui/icons/Store"

// core components (from template)
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"

// Context & API
import authContext from "../../context/AuthContext"

// Styling
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js"
import buttonStyles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle"
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch"
import "./event.css"
import { Tooltip } from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"
import { Drafts, HelpRounded, InfoRounded, InsertPhoto } from "@material-ui/icons"
import Divider from "@material-ui/core/Divider"
import CropperX from "../../components/CropperX"
import { confirmAlert } from "react-confirm-alert"
import ArrowBack from "@material-ui/icons/ArrowBack"
import Close from "@material-ui/icons/Close"
import Warning from "@material-ui/icons/Warning"

const localStyles = {
  centerContainer: {
    alignItems: "center",
  },
  dateContainer: {
    paddingLeft: "20px",
    paddingRight: "20px",
    // border: "1px solid rgba(0, 50, 0, 0.5)",
  },
  textError: {
    color: "#f44336",
    textAlign: "center",
    fontSize: 14,
    fontStyle: "italic",
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  centerIt: {
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  backButton: {
    right: 30,
    position: "fixed",
    zIndex: 999,
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
}
const disabledStyle = {
  disabledBack: {
    backgroundColor: "red",
  },
}

const useDisabledStyle = makeStyles(disabledStyle)
const useButtonStyles = makeStyles(buttonStyles)
const useLocalStyle = makeStyles(localStyles)
const useStyles = makeStyles(styles)
const useStylesExtended = makeStyles(stylesExtended)

const BASE_URL = config.BASE_URL

const label = {
  formTitle: "Détails du nouvel événement",
  visuel: "Visuel",
  company: "société",
  companies: "Choisir un ou plusieurs établissements",
  name: "Nom de l'événement",
  description: "description",
  allTheDay: "Toute la journée (en fonction des horaires de chaque établissement)",
  dtDay: "Jour de l'événement",
  dtStart: "Heure de début",
  dtEnd: "Heure de fin",
  suspended: "Suspendu ?",
  isFree: "Gratuit",
  freely: "Gratuité",
  tarif: "Tarif",
  tarifPromo: "Tarif promo",
  eventPic: "Image de l'événement",
  draft: "brouillon",
  all: "Tous",
  none: "Aucun",
  dateError: "Vérifier les heures de votre événement.",
  tarifError: "Le tarif 'promo' doit être inférieur au tarif standard.",
  warning:
    "Attention : certains de vos établissements ont dépassé le quota journalier de publication.",
  publish: "Publier maintenant",
  draftBtn: "Enregistrer comme brouillon",
  visuelChoice: "Choisir un visuel",
  back: "retour",
  seeClosedOnes: "Voir les établissements fermés ce jour là",
  closedCompanies: "Liste des Établissements fermés ce jour",
  closedCompany: "voir les établissements",
  forceItAll: "Forcer la publication",
}
const CROP_AREA_ASPECT = 9 / 10
const placeHolder = require("assets/img/placeholder.jpg")

export function EventManager(props) {
  let history = useHistory()
  const { mpId } = useContext(authContext)
  const [showCropper, setShowCropper] = useState(false)
  const [checked, setChecked] = useState([24, 22])
  const [multipleSelect, setMultipleSelect] = useState([])
  const [unavailableShops, setUnavailablShops] = useState([])
  const [shops, setShops] = useState([])
  const [originalShops, setOriginalShops] = useState([])
  const [forceItOverHolliday, setForceItOverHolliday] = useState(false)
  const [data, setData] = useState({
    ev_id: "",
    cname: "",
    ev_name: "",
    details: "",
    dt_from: moment().toDate(),
    dt_to: moment().add(120, "minutes").toDate(),
    city: "",
    is_free: 0,
    tarif: 0,
    tarif_promo: 0,
    is_draft: 0,
    original_event_id: undefined,
    cids: [props.location.state?.cid ?? undefined],
    all_day: false,
  })
  const [originalDate, setOriginalDate] = useState(undefined)
  const [dateError, setDateError] = useState(false)
  const [tarifError, setTarifError] = useState(false)
  const [isFree, setIsFree] = useState(true)
  const [isDraft, setIsDraft] = useState(false)
  const [selectedEnabled, setSelectedEnabled] = useState("b")
  const [selectedValue, setSelectedValue] = useState(null)
  // We need to test evId before setting default null value
  const [modificationEvId] = useState(props.location.state?.evId ?? false)
  const [dateMode, setDateMode] = useState(true)
  const [cid] = useState(props.location.state?.cid ?? undefined)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [currentImage, setCurrentImage] = useState(undefined)
  const [croppedArea, setCroppedArea] = useState(null)
  const [base64Image, setBase64Image] = useState(undefined)
  const [open, setOpen] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [loading, setLoading] = useState(true)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [infoText, setInfoText] = useState("")
  const [subInfoText, setSubInfoText] = useState("")
  const [alertType, setAlertType] = useState("success")
  const [closedMainCompanies, setClosedMainCompanies] = useState(undefined)
  const [closedCompanies, setClosedCompanies] = useState(undefined)
  const [openCompanies, setOpenCompanies] = useState(undefined)
  /**
   *
   * @param _day
   * @returns {JSX.Element}
   * @constructor
   */
  const ClosedCompanyListModal = ({ closedCompanies }) => {
    const localStyles = {
      hm_hyperModalWrapper: {
        zIndex: 4,
        backgroundColor: "green",
      },
    }

    // useEffect(() => {
    //   let dDay = moment.utc(dateOfPublication).isoWeekday() - 1 ?? 0
    //   openClosedCompanyListByMultiproId(mpId, dDay)
    //     .then((r) => {
    //       if (r.data.open) {
    //         setItOpen(r.data.open)
    //       }
    //       if (r.data.closed) {
    //         setClosedCompanies(r.data.closed)
    //       }
    //     })
    //     .catch((e) => {
    //       console.log(
    //         "response to open close company went wrong" +
    //           mpId +
    //           "-" +
    //           dDay +
    //           "\n" +
    //           e
    //       )
    //     })
    // }, [dateOfPublication])

    const useStyles = makeStyles(localStyles)
    const classesHM = useStyles()
    const buttonClasses = useButtonStyles()
    const classesExtended = useStylesExtended()

    return (
      <HyperModal
        isFullscreen={false}
        // classes={classesHM}
        renderOpenButton={(requestOpen) => {
          return (
            <Button color="github" size="sm" onClick={requestOpen} simple={true}>
              <Store className={buttonClasses.icons} /> {label.seeClosedOnes}
            </Button>
          )
        }}
      >
        <GridContainer>
          <GridItem xs={12}>
            <CardBody>
              <legend>{label.closedCompanies}</legend>
              <FormControl fullWidth className={classesExtended.selectFormControl}>
                <InputLabel
                  htmlFor="company-list"
                  className={classesExtended.selectLabel}
                  style={{ marginLeft: 0 }}
                >
                  {label.closedCompany}
                </InputLabel>
                <Select
                  // value={multipleSelect}
                  MenuProps={{ className: classesExtended.selectMenu }}
                  classes={{ select: classesExtended.select }}
                  inputProps={{
                    name: "companyList",
                    id: "company-list",
                  }}
                >
                  {closedCompanies &&
                    closedCompanies.map((shop) => {
                      return (
                        <MenuItem
                          id={shop.id}
                          key={shop.id}
                          classes={{
                            root: classesExtended.selectMenuItem,
                            selected: classesExtended.selectMenuItemSelectedMultiple,
                          }}
                          value={shop.id}
                        >
                          {shop.name}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </CardBody>
          </GridItem>
        </GridContainer>
      </HyperModal>
    )
  }

  /**
   * ##############################################################################
   * ########################################## CROPPER OUTPUT ####################
   * ##############################################################################
   */
  const Output = ({ croppedArea }) => {
    const scale = 100 / croppedArea.width
    const transform = {
      x: `${-croppedArea.x * scale}%`,
      y: `${-croppedArea.y * scale}%`,
      scale,
      width: "calc(100% + 0.5px)",
      height: "auto",
    }

    const imageStyle = {
      transform: `translate3d(${transform.x}, ${transform.y}, 0) scale3d(${transform.scale},${transform.scale},1)`,
      width: transform.width,
      height: transform.height,
    }

    return (
      <div
        className="output"
        style={{ paddingBottom: `${100 / CROP_AREA_ASPECT}%` }}
      >
        <img
          src={
            data?.ev_id && !data?.original_event_id
              ? BASE_URL + "img/events/cover-" + data?.ev_id + ".jpg?date="
              : data?.original_event_id
              ? BASE_URL +
                "img/events/cover-" +
                data?.original_event_id +
                ".jpg?date="
              : undefined
          }
          alt=""
          style={imageStyle}
        />
      </div>
    )
  }

  /**
   * ##############################################################################
   * ########################################## EFFECT ############################
   * ##############################################################################
   */

  useEffect(() => {
    if (modificationEvId && originalDate)
      if (moment(originalDate).toDate() > moment()) setDateMode(true)
      else setDateMode(false)
    else setDateMode(false)
  }, [modificationEvId, originalDate])

  /**
   * Note : Recupering the data if modification state setting null value if not
   */
  useEffect(() => {
    if (modificationEvId)
      eventDetails(modificationEvId).then((r) => {
        //preparation of the event data
        let st = r.data[0]
        setData({ ...data, ...st })

        if (props.location.state?.from === "dashboard") {
          console.log("from dashboard", props.location.state?.cid)
          setData({ ...data, cid: props.location.state?.cid })
        }

        setOriginalDate(st.dt_from)

        setCurrentImage(
          st?.ev_id && !st?.original_event_id
            ? BASE_URL + "img/events/cover-" + st?.ev_id + ".jpg"
            : st?.original_event_id
            ? BASE_URL + "img/events/cover-" + st?.original_event_id + ".jpg"
            : undefined
        )
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(st.details))
          )
        )
        setLoading(false)
      })
    else setLoading(false)
  }, [modificationEvId])

  /**
   * Sociétés ( actives ) ayant publiées
   * todo: need to improve the workflow of shops and closed shops ! (15/03/2023)
   */
  useEffect(() => {
    setCompaniesAllowedToPublish()
  }, [mpId])

  /**
   *
   * @param _force
   */
  const setCompaniesAllowedToPublish = (_force = false) => {
    if (mpId && data.dt_from) {
      companyListThatCanPublish(mpId)
        .then((r) => {
          if (r.data.status === 1) {
            // Converting object of objects to array of objects
            let st = r.data?.companyOK
            if (st) {
              let shopsArray = Object.keys(st).map((key) => {
                return st[key]
              })
              if (!_force) {
                let dDay = moment.utc(data.dt_from).isoWeekday() - 1 ?? 0
                openClosedCompanyListByMultiproId(mpId, dDay)
                  .then((r) => {
                    if (r.data.open) {
                      filterJustTheOneOpened(r.data.open, shopsArray)
                    }
                    if (r.data.closed) {
                      setClosedCompanies(r.data.closed)
                    }
                  })
                  .catch((e) => {
                    console.log(
                      "response to open close company went wrong" +
                        mpId +
                        "-" +
                        dDay +
                        "\n" +
                        e
                    )
                  })
              } else setShops(shopsArray)
            } else setDateMode(true)
            // company that can't publish
            let xt = r.data?.companyKO
            if (xt) {
              let koShopsArray = Object.keys(xt).map((key) => {
                return xt[key]
              })
              setUnavailablShops(koShopsArray)
            }
          } else {
            history.replace("/admin/event-list")
            console.log("Something going wrong with company listing")
          }
        })
        .catch((e) => {
          console.error(e)
        })
    }
  }

  /**
   *
   */
  const filterJustTheOneOpened = (_p, _allowedShops) => {
    console.log("shop", _allowedShops)
    if (_allowedShops) {
      // Now we need to filter for opening companies only
      let openOnly = _allowedShops.filter((shop, key) => {
        let id = _p.find((e) => e.id === shop.id)
        if (id && id.id === shop.id) return shop
        else console.log("shop closed", shop.name)
      })
      console.log("open only", openOnly)
      setShops(openOnly)
    }
  }

  /**
   * Note : initialisation de la selection de la société en cours (événement associé)
   */
  useEffect(() => {
    if (cid) {
      setMultipleSelect([cid])
    }
  }, [cid])

  /**
   * Note controlling date validity
   */
  useEffect(() => {
    // Date analyzer
    if (data.dt_to && data.dt_from && data.dt_to <= data.dt_from) {
      setDateError(true)
    } else setDateError(false)

    setCompaniesAllowedToPublish()
  }, [data.dt_from, data.dt_to])

  /**
   * Note : tarif work
   */
  useEffect(() => {
    // Date analyzer
    // if (data.tarif && data.tarif_promo && data.tarif < data.tarif_promo) {
    //   console.log("tarif 1 if", data.tarif, data.tarif_promo, data.is_free)
    //   setTarifError(true)
    // } else
    if (
      data.is_free !== true &&
      parseFloat(data.tarif) < parseFloat(data.tarif_promo)
    ) {
      setTarifError(true)
    } else if (data.tarif && data.tarif_promo) setTarifError(false)
  }, [data.tarif, data.tarif_promo, data.is_free])

  /**
   * ##############################################################################
   * ########################################## EFFECT END ########################
   * ##############################################################################
   */

  /**
   * Note : handle multiple select with "all managing"
   * @param event
   */
  const handleMultiple = (event) => {
    if (event.target.value.includes(label.all)) {
      let st = []
      shops.map((shop) => {
        return st.push(shop.id)
      })
      setMultipleSelect(st)
      setData({ ...data, cids: st })
    } else if (event.target.value.includes(label.none)) {
      setMultipleSelect([])
      setData({ ...data, cids: undefined })
    } else {
      setMultipleSelect(event.target.value)
      setData({ ...data, cids: event.target.value })
    }
  }

  /**
   *
   * @param _editor
   */
  const onEditorStateChange = (_editor) => {
    setEditor(_editor)
  }

  /**
   * Note: Changing only the date (not the time)
   * @param _workDate
   * @returns {Date}
   */
  const changeEndDate = (_workDate) => {
    let d = _workDate.getDate()
    let m = _workDate.getMonth()
    let y = _workDate.getFullYear()
    let x = moment.utc(data.dt_to).toDate()
    x.setMonth(m)
    x.setFullYear(y)
    x.setDate(d)
    // console.log("day", moment.utc(x).isoWeekday())
    return x
  }

  /**
   * Note: management of simple input
   * @param _text
   * @param _fromWich
   */
  const handleInputChange = (_text, _fromWich) => {
    if (_fromWich !== "dt_from") setData({ ...data, [_fromWich]: _text })
    else {
      let stDate = changeEndDate(_text)
      setData((prevState) => {
        return { ...prevState, [_fromWich]: _text, dt_to: stDate }
      })
    }
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }

  const handleChangeEnabled = (event) => {
    setSelectedEnabled(event.target.value)
  }

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  /**
   * Note: redirection after validation message
   */
  const redirect = () => {
    let st = ""
    if (props.location.state?.from === "dashboard") st = "/admin/dashboard"
    else st = "/admin/event-list"

    history.push({
      pathname: st,
    })
  }

  /**
   * Note : Validate the date and update or insert an event
   * isNewEvent (modificationEvId to false)
   */
  const handleSubmission = async (_draft = false) => {
    let stopProcess = false
    let moreInfoText = []

    setLoading(true)
    // Is new image to submit
    let isNewImage = false
    if (base64Image) isNewImage = true

    // Company (at least one)
    if (data.cids && data.cids[0] === undefined) {
      stopProcess = true
      // prettier-ignore
      moreInfoText.push("- Sélectionnez au moins un établissement")
    }

    // Event Name
    if (data.ev_name.length === 0) {
      stopProcess = true
      moreInfoText.push("- Le nom de l'événement est obligatoire")
    }

    // Event Description
    if (data.details.length === 0) {
      stopProcess = true
      moreInfoText.push("- La description est obligatoire")
    }

    // Image
    if (!modificationEvId && base64Image === undefined) {
      stopProcess = true
      moreInfoText.push("- Le visuel est obligatoire")
    }

    // date logic
    if (data.dt_to <= data.dt_from) {
      stopProcess = true
      moreInfoText.push("- Vérifiez la cohérence des heures")
    }

    // tarif logic
    if (
      parseFloat(data.tarif) < parseFloat(data.tarif_promo) &&
      data.is_free !== true
    ) {
      stopProcess = true
      moreInfoText.push("- Vérifiez la cohérence de vos tarifs")
    }

    // Let' go
    if (!stopProcess) {
      if (modificationEvId) {
        confirmAlert({
          title: "Confirmer",
          message: `Vous ne pourrez plus modifier la date et l'heure de cet événement.`,
          buttons: [
            {
              label: "Je valide",
              onClick: () => {
                updateEvent(data, cid, base64Image, isNewImage, _draft, mpId)
                  .then((response) => {
                    // Give user control back
                    setAlertType("success")
                    setInfoText("Bravo, vos modifications ont été enregistrées")
                    setSubInfoText(["...et elles sont déjà actives."])
                    setLoading(false)
                    setShowAlert(true)
                  })
                  .then((response) => {
                    notificationByEmailOnEventActionByMultipro(data, mpId)
                  })
                  .catch((errors) => {
                    console.log("errors ", errors)
                    // Alert update date or upload image imposssible
                    setLoading(false)
                    alert("Une erreur est survenue")
                  })
              },
            },
            {
              label: "Je vérifie encore",
              onClick: () => {
                setLoading(false)
              },
            },
          ],
        })
      } else {
        confirmAlert({
          title: "Confirmer",
          message: `Vous ne pourrez plus modifier la date et l'heure de cet événement`,
          buttons: [
            {
              label: "Je valide",
              onClick: () => {
                uploadNewEvent(data, base64Image, _draft, mpId)
                  .then((response) => {
                    // Give user control back
                    setAlertType("success")
                    setInfoText("Bravo, vos modifications ont été enregistrées")
                    setSubInfoText(["...et elles sont déjà actives."])
                    setLoading(false)
                    setShowAlert(true)
                  })
                  .then((response) => {
                    notificationByEmailOnEventActionByMultipro(data, mpId).then(
                      (response) => {
                        console.log("after emailing", response)
                      }
                    )
                  })
                  .catch((errors) => {
                    console.log("errors ", errors)
                    // Alert update date or upload image imposssible
                    setLoading(false)
                    alert("Une erreur est survenue")
                  })
              },
            },
            {
              label: "Je vérifie encore",
              onClick: () => {
                setLoading(false)
              },
            },
          ],
        })
      }
    } else {
      setInfoText("Aie, vérifiez vos saisies")
      setSubInfoText(moreInfoText)
      setAlertType("warning")
      setLoading(false)
      setShowAlert(true)
    }
  }

  /**
   * Note : Alert manager
   */
  const showInfo = () => {
    setShowAlert(!showAlert)
  }

  // Style construction (multi theme options...)
  const buttonClasses = useButtonStyles()
  const classes = useStyles()
  const classesExtended = useStylesExtended()
  const classesLocal = useLocalStyle()
  const disabled = useDisabledStyle()
  return (
    <div>
      {!loading ? (
        <GridContainer>
          {/*Large Form*/}
          <JAlert
            type={alertType}
            show={showAlert}
            localStatus={showInfo}
            feedBack={redirect}
            titleText={infoText}
            subTitleText={subInfoText}
          />
          <GridItem xs={12}>
            <Button
              style={localStyles.backButton}
              color="github"
              onClick={() => {
                redirect()
              }}
            >
              <ArrowBack className={buttonClasses.icons} /> {label.back}
            </Button>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>
                    {label.formTitle}
                    <Tooltip
                      title={
                        <p style={{ fontSize: 12 }}>
                          Remplir tous les champs obligatoirement ainsi que la photo
                        </p>
                      }
                    >
                      <IconButton>
                        <InfoRounded style={{ color: "#42988d" }} />
                      </IconButton>
                    </Tooltip>
                  </h4>
                </CardText>
                <Button
                  simple
                  href="/faq#menu-6"
                  target="_blank"
                  rel="noopener, noreferrer"
                  style={{
                    height: 25,
                    backgroundColor: "transparent",
                    color: "#494949",
                  }}
                >
                  <h6 style={{ fontSize: "1em" }}>Documentation</h6>
                </Button>
              </CardHeader>
              <CardBody>
                {/** ################################################ **/}
                {/** ####################  Title  ################### **/}
                {/** ################################################ **/}
                <GridContainer
                  container
                  spacing={1}
                  direction="row"
                  alignItems="center"
                >
                  <GridItem xs={12}>
                    <legend>
                      {label.name}
                      <Tooltip
                        title={
                          <p style={{ fontSize: 12 }}>
                            3 à 4 mots maximum en MAJUSCULES c'est plus joli
                          </p>
                        }
                      >
                        <IconButton style={{ height: 18 }}>
                          <InfoRounded style={{ color: "#42988d" }} />
                        </IconButton>
                      </Tooltip>
                    </legend>
                    {unavailableShops && unavailableShops.length > 0 && (
                      <legend align="center" style={localStyles.textError}>
                        {label.warning}
                      </legend>
                    )}
                    <CustomInput
                      labelText={label.name}
                      id="eventName"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        value: data?.ev_name,
                        onChange: (e) => {
                          handleInputChange(e.target.value, "ev_name")
                        },
                      }}
                    />
                  </GridItem>

                  {/** ################################################ **/}
                  {/** ####################  PERIOD ################### **/}
                  {/** ################################################ **/}
                  <GridContainer>
                    <GridItem xs={12} sm={4}>
                      <CardBody>
                        <FormControl fullWidth>
                          <legend>
                            {label.dtDay}
                            <Tooltip
                              title={
                                <>
                                  <p style={{ fontSize: 12 }}>
                                    Par défaut c'est aujourd'hui
                                  </p>
                                  <p style={{ fontSize: 12 }}>
                                    Indiquez aussi les heures avec précision
                                  </p>
                                  <p style={{ fontSize: 12 }}>
                                    Si vous choisissez l'option "toute la journée"
                                    les horaires de cet événement seront identiques
                                    aux horaires d'ouverture de l'établissement et
                                    sont susceptibles d'être modifiés si les horaires
                                    de l'établissement changent.
                                  </p>
                                </>
                              }
                            >
                              <IconButton style={{ height: 18 }}>
                                <InfoRounded style={{ color: "#42988d" }} />
                              </IconButton>
                            </Tooltip>
                          </legend>
                          <MuiPickersUtilsProvider locale="fr" utils={MomentUtils}>
                            <KeyboardDatePicker
                              disabled={dateMode}
                              clearable
                              value={
                                data?.dt_from ? moment(data?.dt_from).toDate() : ""
                              }
                              placeholder=""
                              onChange={(e) => {
                                let st = moment(e).toDate()
                                handleInputChange(st, "dt_from")
                              }}
                              minDate={new Date()}
                              format="DD/MM/yyyy"
                              okLabel="ok"
                              clearLabel="Effacer"
                              cancelLabel="Annuler"
                              helperText={dateError ? "attention aux heures" : ""}
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </CardBody>
                    </GridItem>
                    {!!Number(data.all_day) === false ? (
                      <>
                        <GridItem xs={12} sm={4}>
                          <CardBody>
                            <FormControl fullWidth>
                              <legend>{label.dtStart}</legend>
                              <MuiPickersUtilsProvider
                                locale="fr"
                                utils={MomentUtils}
                              >
                                <KeyboardTimePicker
                                  disabled={dateMode ? true : false}
                                  ampm={false}
                                  mask="__:__ _M"
                                  value={
                                    data?.dt_from
                                      ? moment(data?.dt_from).toDate()
                                      : ""
                                  }
                                  onChange={(e) => {
                                    let st = moment(e).toDate()
                                    handleInputChange(st, "dt_from")
                                  }}
                                  okLabel="ok"
                                  clearLabel="Effacer"
                                  cancelLabel="Annuler"
                                  helperText={
                                    data.dt_to <= data.dt_from
                                      ? "attention aux heures"
                                      : ""
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </CardBody>
                        </GridItem>
                        <GridItem xs={12} sm={4}>
                          <CardBody>
                            <FormControl fullWidth>
                              <legend>{label.dtEnd}</legend>
                              <MuiPickersUtilsProvider
                                locale="fr"
                                utils={MomentUtils}
                              >
                                <KeyboardTimePicker
                                  disabled={dateMode ? true : false}
                                  ampm={false}
                                  mask="__:__ _M"
                                  value={
                                    data?.dt_to ? moment(data?.dt_to).toDate() : ""
                                  }
                                  onChange={(e) => {
                                    let st = moment(e).toDate()
                                    handleInputChange(st, "dt_to")
                                  }}
                                  okLabel="ok"
                                  clearLabel="Effacer"
                                  cancelLabel="Annuler"
                                  helperText={
                                    data.dt_to <= data.dt_from
                                      ? "attention aux heures"
                                      : ""
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </CardBody>
                        </GridItem>
                      </>
                    ) : null}
                    {dateError && (
                      <GridItem xs={12}>
                        <legend style={localStyles.textError}>
                          {label.dateError}
                        </legend>
                      </GridItem>
                    )}

                    {/** ################################################ **/}
                    {/** ####################  ALL THE DAY ############## **/}
                    {/** ################################################ **/}
                    <GridItem xs={12}>
                      <CardBody>
                        <FormControl>
                          <div className={classesExtended.block}>
                            <FormControlLabel
                              control={
                                <Switch
                                  disabled={dateMode}
                                  id="allDay"
                                  checked={!!Number(data.all_day)}
                                  onChange={(event) => {
                                    handleInputChange(
                                      event.target.checked,
                                      "all_day"
                                    )
                                  }}
                                  //value={isFree}
                                  classes={{
                                    switchBase: classesExtended.switchBase,
                                    checked: classesExtended.switchChecked,
                                    thumb: classesExtended.switchIcon,
                                    track: classesExtended.switchBar,
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                              }}
                              label={label.allTheDay}
                            />
                          </div>
                        </FormControl>
                      </CardBody>
                    </GridItem>

                    {/** ################################################ **/}
                    {/** ###############  CLOSED COMPANIES MODAL ######## **/}
                    {/** ################################################ **/}
                    <GridItem xs={3}>
                      <Button
                        color={forceItOverHolliday ? "warning" : "github"}
                        size="sm"
                        onClick={() => {
                          setForceItOverHolliday(!forceItOverHolliday)
                          setCompaniesAllowedToPublish(!forceItOverHolliday)
                        }}
                        simple={true}
                      >
                        <Warning className={buttonClasses.icons} />{" "}
                        {label.forceItAll}
                      </Button>
                    </GridItem>
                    {forceItOverHolliday === false && (
                      <GridItem xs={3}>
                        <ClosedCompanyListModal closedCompanies={shops} />
                      </GridItem>
                    )}
                  </GridContainer>

                  {/** ################################################ **/}
                  {/** ####################  TARIF #################### **/}
                  {/** ################################################ **/}
                  <GridContainer direction="row">
                    <GridItem xs={12} sm={12} md={4}>
                      <CardBody>
                        <FormControl fullWidth>
                          <legend>
                            {label.freely}
                            <Tooltip
                              title={
                                <>
                                  <p style={{ fontSize: 12 }}>
                                    Pas de surprise pour vos clients
                                  </p>
                                  <p style={{ fontSize: 12 }}>
                                    Indication du tarif obligatoire
                                  </p>
                                  <p style={{ fontSize: 12 }}>
                                    Sauf si Gratuité elle sera indiquée comme telle
                                  </p>
                                </>
                              }
                            >
                              <IconButton style={{ height: 18 }}>
                                <InfoRounded style={{ color: "#42988d" }} />
                              </IconButton>
                            </Tooltip>
                          </legend>
                          <div className={classesExtended.block}>
                            <FormControlLabel
                              control={
                                <Switch
                                  id="free"
                                  checked={!!Number(data.is_free)}
                                  onChange={(event) => {
                                    setIsFree(event.target.checked)
                                    handleInputChange(
                                      event.target.checked,
                                      "is_free"
                                    )
                                  }}
                                  //value={isFree}
                                  classes={{
                                    switchBase: classesExtended.switchBase,
                                    checked: classesExtended.switchChecked,
                                    thumb: classesExtended.switchIcon,
                                    track: classesExtended.switchBar,
                                  }}
                                />
                              }
                              classes={{
                                label: classes.label,
                              }}
                              label={label.isFree}
                            />
                          </div>
                        </FormControl>
                      </CardBody>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CardBody>
                        <FormControl fullWidth>
                          <legend>{label.tarif}</legend>
                          <CustomInput
                            // labelText={label.tarif}
                            id="tarif"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: !!Number(data.is_free),
                              placeholder: "tarif",
                              type: "number",
                              value: data?.tarif,
                              onChange: (e) => {
                                handleInputChange(e.target.value, "tarif")
                              },
                            }}
                          />
                        </FormControl>
                      </CardBody>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CardBody>
                        <FormControl fullWidth>
                          <legend>{label.tarifPromo}</legend>
                          <CustomInput
                            // labelText={label.tarifPromo}
                            id="tarifPromo"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              disabled: !!Number(data.is_free),
                              placeholder: "tarif promo",
                              type: "number",
                              value: data?.tarif_promo,
                              onChange: (e) => {
                                handleInputChange(e.target.value, "tarif_promo")
                              },
                            }}
                          />
                        </FormControl>
                      </CardBody>
                    </GridItem>
                    {tarifError === true && (
                      <GridItem xs={12}>
                        <legend style={localStyles.textError}>
                          {label.tarifError}
                        </legend>
                      </GridItem>
                    )}
                  </GridContainer>
                  {/** ################################################ **/}
                  {/** ####################  Details ################## **/}
                  {/** ################################################ **/}
                  <GridContainer direction="row">
                    <GridItem xs={12} sm={6}>
                      <CardBody>
                        <legend>
                          {label.description}
                          <Tooltip
                            style={{ fontSize: 14 }}
                            title={
                              <>
                                <p style={{ fontSize: 12 }}>
                                  Courte, c'est plus efficace pour la lecture et la
                                  traduction automatique
                                </p>
                                <p style={{ fontSize: 12 }}>
                                  Évitez les articles le,la, un,une....
                                </p>
                              </>
                            }
                          >
                            <IconButton style={{ height: 18 }}>
                              <InfoRounded style={{ color: "#42988d" }} />
                            </IconButton>
                          </Tooltip>
                        </legend>
                        <Editor
                          toolbar={{
                            options: [
                              "inline",
                              "blockType",
                              "fontSize",
                              "fontFamily",
                              "list",
                              "textAlign",
                              "link",
                              "embedded",
                              "emoji",
                              "history",
                            ],
                            inline: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                              options: [
                                "bold",
                                "italic",
                                "underline",
                                "strikethrough",
                                "monospace",
                                "superscript",
                                "subscript",
                              ],
                            },
                          }}
                          editorState={editorState}
                          // toolbarClassName="toolbarClassName"
                          // wrapperClassName="demo-wrapper"
                          // editorClassName="demo-editor"
                          editorStyle={{
                            display: showCropper ? "none" : "block",
                            backgroundColor: "#dbdcdc",
                            height: "160px",
                          }}
                          onEditorStateChange={setEditorState}
                          onContentStateChange={() => {
                            let st = draftToHtml(
                              convertToRaw(editorState.getCurrentContent())
                            )
                            setData((prevState) => {
                              return { ...prevState, details: st }
                            })
                          }}
                        />
                      </CardBody>

                      {/** ################################################ **/}
                      {/** ###################  COMPANNIES ################ **/}
                      {/** ################################################ **/}
                      <CardBody>
                        <legend>{label.companies}</legend>
                        <FormControl
                          fullWidth
                          className={classesExtended.selectFormControl}
                        >
                          <InputLabel
                            htmlFor="company-list"
                            className={classesExtended.selectLabel}
                            style={{ marginLeft: 0 }}
                          >
                            {label.companies}
                          </InputLabel>
                          <Select
                            multiple
                            value={multipleSelect}
                            onChange={handleMultiple}
                            MenuProps={{ className: classesExtended.selectMenu }}
                            classes={{ select: classesExtended.select }}
                            inputProps={{
                              name: "companyList",
                              id: "company-list",
                            }}
                          >
                            <MenuItem
                              classes={{
                                root: classesExtended.selectMenuItem,
                              }}
                              value={
                                multipleSelect.length === shops.length
                                  ? label.none
                                  : label.all
                              }
                            >
                              {multipleSelect.length === shops.length
                                ? label.none
                                : label.all}
                            </MenuItem>
                            {shops.map((shop) => {
                              return (
                                <MenuItem
                                  id={shop.id}
                                  key={shop.id}
                                  classes={{
                                    root: classesExtended.selectMenuItem,
                                    selected:
                                      classesExtended.selectMenuItemSelectedMultiple,
                                  }}
                                  value={shop.id}
                                >
                                  {shop.name}
                                </MenuItem>
                              )
                            })}
                            <Divider />
                            {unavailableShops &&
                              unavailableShops.map((shop) => {
                                return (
                                  <MenuItem
                                    id={shop.id}
                                    key={shop.id}
                                    disabled
                                    classes={{
                                      root: classesExtended.disabledMenuItem,
                                      selected:
                                        classesExtended.selectMenuItemSelectedMultiple,
                                    }}
                                    value={shop.id}
                                  >
                                    {shop.name}
                                  </MenuItem>
                                )
                              })}
                          </Select>
                        </FormControl>
                      </CardBody>
                    </GridItem>
                    {/** ################################################ **/}
                    {/** ####################  VISUEL       ############# **/}
                    {/** ################################################ **/}
                    <GridItem xs={12} sm={6} md={6} style={localStyles.centerIt}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={base64Image ?? currentImage ?? placeHolder.default}
                          alt="justnow"
                          style={{
                            height: 400,
                            boxShadow: "0 0 32px rgb(0 0 0 / 30%)",
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            color="info"
                            round
                            className={buttonClasses.marginTop}
                            onClick={() => {
                              setShowCropper(!showCropper)
                            }}
                          >
                            <InsertPhoto className={buttonClasses.icons} />{" "}
                            {label.visuelChoice}
                          </Button>
                          <Tooltip
                            title={
                              <p style={{ fontSize: 12 }}>
                                <p>
                                  La photo doit correspondre exactement à
                                  l'événement.
                                </p>
                                <p>Comme aux USA "What you see is what you get'</p>
                                <p>
                                  Utiliser tout l'écran pour maximiser l'impact du
                                  visuel
                                </p>
                              </p>
                            }
                          >
                            <IconButton style={{ height: 18, alignItems: "normal" }}>
                              <InfoRounded style={{ color: "#42988d" }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </GridItem>
                  </GridContainer>

                  {/** ################################################ **/}
                  {/** ####################  Crop    ################## **/}
                  {/** ################################################ **/}
                  {/*<Crop*/}
                  {/*  title={label.eventPic}*/}
                  {/*  image={currentImage}*/}
                  {/*  b64={(x) => {*/}
                  {/*    setBase64Image(x)*/}
                  {/*  }}*/}
                  {/*  direction="row"*/}
                  {/*/>*/}
                  {/** ################################################ **/}
                  {/** ####################  DRAFT OPTION ############# **/}
                  {/** ################################################ **/}
                  {/*<GridContainer*/}
                  {/*  container*/}
                  {/*  spacing={0}*/}
                  {/*  direction="column"*/}
                  {/*  alignItems="center"*/}
                  {/*>*/}
                  {/*  <GridItem>*/}
                  {/*    <FormControl fullWidth>*/}
                  {/*      /!*<legend>{label.draft}</legend>*!/*/}
                  {/*      <div className={classesExtended.block}>*/}
                  {/*        <FormControlLabel*/}
                  {/*          control={*/}
                  {/*            <Switch*/}
                  {/*              id="draft"*/}
                  {/*              checked={!!Number(data.is_draft)}*/}
                  {/*              onChange={(event) => {*/}
                  {/*                setIsDraft(event.target.checked)*/}
                  {/*                handleInputChange(event.target.checked, "is_draft")*/}
                  {/*              }}*/}
                  {/*              // value="isDraft"*/}
                  {/*              classes={{*/}
                  {/*                switchBase: classesExtended.switchBase,*/}
                  {/*                checked: classesExtended.switchChecked,*/}
                  {/*                thumb: classesExtended.switchIcon,*/}
                  {/*                track: classesExtended.switchBar,*/}
                  {/*              }}*/}
                  {/*            />*/}
                  {/*          }*/}
                  {/*          classes={{*/}
                  {/*            label: classes.label,*/}
                  {/*          }}*/}
                  {/*          label={label.draft}*/}
                  {/*        />*/}
                  {/*      </div>*/}
                  {/*    </FormControl>*/}
                  {/*  </GridItem>*/}
                  {/*</GridContainer>*/}

                  {/** ################################################ **/}
                  {/** ####################  VISUEL TESTER ############ **/}
                  {/** ################################################ **/}

                  {/*<GridContainer*/}
                  {/*  container*/}
                  {/*  spacing={0}*/}
                  {/*  direction="row"*/}
                  {/*  alignItems="center"*/}
                  {/*  style={localStyles.centerIt}*/}
                  {/*>*/}
                  {/*  <GridItem xs={12} sm={6} md={6}>*/}
                  {/*    <img*/}
                  {/*      src={base64Image}*/}
                  {/*      alt="justnow"*/}
                  {/*      style={{*/}
                  {/*        border: "solid",*/}
                  {/*        borderWidth: 2,*/}
                  {/*        height: 400,*/}
                  {/*      }}*/}
                  {/*    />*/}
                  {/*    <Button*/}
                  {/*      color="success"*/}
                  {/*      round*/}
                  {/*      className={buttonClasses.marginTop}*/}
                  {/*      onClick={() => {*/}
                  {/*        setShowCropper(!showCropper)*/}
                  {/*      }}*/}
                  {/*    >*/}
                  {/*      <InsertPhoto className={buttonClasses.icons} />{" "}*/}
                  {/*      {label.visuelChoice}*/}
                  {/*    </Button>*/}
                  {/*  </GridItem>*/}
                  {/*</GridContainer>*/}
                  {/** ################################################ **/}
                  {/** ####################  VALIDATION ############### **/}
                  {/** ################################################ **/}
                  <GridContainer
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    style={localStyles.centerIt}
                  >
                    <Divider />
                    <GridItem xs={12} sm={6} md={6} style={{ textAlign: "right" }}>
                      <Button
                        color="success"
                        round
                        className={buttonClasses.marginTop}
                        onClick={() => {
                          handleSubmission(false)
                        }}
                      >
                        <Favorite className={buttonClasses.icons} /> {label.publish}
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} style={{ textAlign: "left" }}>
                      <Button
                        color="warning"
                        round
                        className={buttonClasses.marginTop}
                        onClick={() => {
                          handleSubmission(true)
                        }}
                      >
                        <Drafts className={buttonClasses.icons} /> {label.draftBtn}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          <CropperX
            visibility={showCropper}
            backInfo={() => setShowCropper(!showCropper)}
            title={label.eventPic}
            image={currentImage}
            b64={(x) => {
              setBase64Image(x)
            }}
            direction="row"
          />
        </GridContainer>
      ) : (
        <div className={disabled.disabledBack}>
          <GridLoader
            color="#3f4850"
            loading={loading}
            size={50}
            css={`
              position: absolute;
              display: block;
              z-index: 999;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            `}
          />
        </div>
      )}
    </div>
  )
}

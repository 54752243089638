export function parseBool(_isBool) {
  switch (_isBool) {
    case true:
    case "yes":
    case 1:
    case "1":
      return true
    case false:
    case "no":
    case 0:
    case "0":
    case null:
      return false
    default:
      return Boolean(_isBool)
  }
}

export function removeHTMLTags(_str) {
  // Description HTML preparation & cleaning
  const regex = /(<([^>]+)>)/gi
  let clean = _str.replace(regex, "")
  clean = clean.replace(/&nbsp;/g, "")
  return clean
}

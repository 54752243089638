import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAlert } from "react-alert"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment"
import Dvr from "@material-ui/icons/Dvr"
import Close from "@material-ui/icons/Close"

// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardIcon from "components/Card/CardIcon.js"
import CardHeader from "components/Card/CardHeader.js"
import ReactTable from "components/ReactTable/ReactTable.js"

// Private Component
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js"
import { eventList, removeEvents } from "../../../api/eventAction"
import authContext from "../../../context/AuthContext"
import config from "../../../config"
import { removeHTMLTags } from "../../../utils/functions"
import { DeleteForever } from "@material-ui/icons"
import { confirmAlert } from "react-confirm-alert"
import { removeCompanyAndUser } from "../../../api/companyAction"
import Favorite from "@material-ui/icons/Favorite"
import { setFilterMemo } from "../../../services/LocaleStorage"

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
}

const label = {
  messageOK: "Bravo, modification effectuée !",
  messageKO: "Aie, veuillez réessayer ou contacter l'administrateur.",
}

const BASE_URL = config.BASE_URL
const useStyles = makeStyles(styles)

export function EventList() {
  const { mpId } = useContext(authContext)
  const [event, setEvent] = useState(undefined)
  const [filter, setFilter] = useState({ draft: 0, running: 2, last7: 0, group: 0 })
  const history = useHistory()
  const alert = useAlert()

  // const [data, setData] = useState(
  //   events.map((p, key) => {
  //     return {
  //       id: key,
  //       name: p.name,
  //       shop: p.shop,
  //       description: p.description,
  //       dtFrom: p.dtFrom,
  //       dtEnd: p.dtEnd,
  //       suspended: p.suspended,
  //       actions: (
  //         // we've added some custom button actions
  //         <div className="actions-right">
  //           {/* use this button to add a like kind of action */}
  //           {/*<Button*/}
  //           {/*  justIcon*/}
  //           {/*  round*/}
  //           {/*  simple*/}
  //           {/*  onClick={() => {*/}
  //           {/*    let obj = data.find((o) => o.id === key)*/}
  //           {/*    alert(*/}
  //           {/*      "You've clicked LIKE button on \n{ \nName: " +*/}
  //           {/*        obj.name +*/}
  //           {/*        ", \nposition: " +*/}
  //           {/*        obj.position +*/}
  //           {/*        ", \noffice: " +*/}
  //           {/*        obj.office +*/}
  //           {/*        ", \nage: " +*/}
  //           {/*        obj.age +*/}
  //           {/*        "\n}."*/}
  //           {/*    )*/}
  //           {/*  }}*/}
  //           {/*  color="info"*/}
  //           {/*  className="like"*/}
  //           {/*>*/}
  //           {/*  <Favorite />*/}
  //           {/*</Button>{" "}*/}
  //           {/* use this button to add a edit kind of action */}
  //           <Button
  //             justIcon
  //             round
  //             simple
  //             onClick={() => {
  //               let obj = data.find((o) => o.id === key)
  //               alert(
  //                 "You've clicked EDIT button on \n{ \nName: " +
  //                   obj.name +
  //                   ", \nposition: " +
  //                   obj.position +
  //                   ", \noffice: " +
  //                   obj.office +
  //                   ", \nage: " +
  //                   obj.age +
  //                   "\n}."
  //               )
  //             }}
  //             color="warning"
  //             className="edit"
  //           >
  //             <Dvr />
  //           </Button>{" "}
  //           {/* use this button to remove the data row */}
  //           <Button
  //             justIcon
  //             round
  //             simple
  //             onClick={() => {
  //               var newData = data
  //               newData.find((o, i) => {
  //                 if (o.id === key) {
  //                   // here you should add some custom code so you can delete the data
  //                   // from this component and from your server as well
  //                   newData.splice(i, 1)
  //                   return true
  //                 }
  //                 return false
  //               })
  //               setData([...newData])
  //             }}
  //             color="danger"
  //             className="remove"
  //           >
  //             <Close />
  //           </Button>{" "}
  //         </div>
  //       ),
  //     }
  //   })
  // )

  useEffect(() => {
    if (mpId) loadEvents(0, 2, 0, 0)
  }, [mpId])

  /**
   * Note :
   * @param _draft
   * @param _running
   * @param _last7
   * @param _group
   */
  const loadEvents = (_draft = 0, _running = 2, _last7 = 0, _group = 0) => {
    eventList(mpId, _running, _group, _draft, "az", _last7)
      .then((response) => {
        if (response.status === 200 && response.data.status === 1) {
          let st = response.data.data
          if (st.length > 0) {
            setEvent(
              st.map((v, k) => {
                // prepare data for actions buttons (!)
                // todo : algo for delete option
                return {
                  cover: v.cover,
                  ev_id: v.ev_id,
                  cid: v.cid,
                  c_name: v.c_name,
                  ev_name: v.ev_name,
                  details: v.details ? removeHTMLTags(v.details) : "",
                  dt_from: v.dt_from,
                  dt_to: v.dt_to,
                  city: v.city,
                  is_free: v.is_free,
                  tarif: v.tarif + "\\" + v.tarif_promo,
                  suspended: v.suspended,
                  visits: v.visits,
                  draft: v.is_draft,
                  actions: actionButton(st, v.ev_id),
                }
              })
            )
          } else {
            setEvent([])
          }
        }
      })
      .catch((e) => console.log(e))
  }

  /**
   * Note : Manage filter and reload data
   * @param _filter
   */
  const changeFilter = (_filterName, _filterValue) => {
    let st = filter

    if (_filterName)
      switch (_filterName) {
        case "draft":
          st.draft = _filterValue ? 2 : 0
          break
        case "last7":
          st.last7 = _filterValue ? 1 : 0
          break
        case "running":
          st.running = _filterValue ? 1 : 2
          break
      }
    // Update state value for next use memory
    setFilter(st)
    loadEvents(st.draft, st.running, st.last7, null)
  }

  /**
   *
   * @param _newData
   * @param _id
   * @param _eventName
   */
  const handleDelete = (_newData, _id, _eventName) => {
    confirmAlert({
      title: "Confirmer",
      message: `êtes vous sûr de vouloir supprimer ${_eventName} ?`,
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            removeEvents(_id)
              .then((response) => {
                if (response === "ok") {
                  setEvent([..._newData])
                  alert.success(label.messageOK)
                }
              })
              .catch((error) => {
                console.log(error)
                alert.error(label.messageKO)
              })
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    })
  }

  /**
   *
   * @param _event
   * @param _id
   * @returns {JSX.Element}
   */
  const actionButton = (_event, _id) => {
    return (
      <div className="actions-right">
        <Button
          justIcon
          round
          simple
          onClick={() => {
            let obj = _event.find((o) => o.ev_id === _id)
            if (obj) {
              const path = "/admin/event-manager"
              history.push({
                pathname: path,
                state: { evId: obj.ev_id, cid: obj.cid },
              })
            } else alert("nothing found : " + obj)
          }}
          color="info"
          className="edit"
        >
          <Dvr />
        </Button>{" "}
        {/* use this button to remove the data row */}
        <Button
          justIcon
          round
          simple
          onClick={() => {
            let newData = _event
            let eventName = ""
            newData.find((o, i) => {
              if (o.ev_id === _id) {
                newData.splice(i, 1)
                eventName = o.ev_name
                return true
              }
              return false
            })
            newData.map((obj) => {
              obj.actions = actionButton(newData, obj.ev_id)
            })

            // Removing event  from API _id === _cid
            handleDelete(newData, _id, eventName)
            // removeEvents(_id)
            //   .then((response) => {
            //     if (response === "ok") {
            //       setEvent([...newData])
            //       alert.success(label.messageOK)
            //     }
            //   })
            //   .catch((error) => {
            //     console.log(error)
            //     alert.error(label.messageKO)
            //   })
          }}
          color="danger"
          className="remove"
        >
          <DeleteForever />
        </Button>{" "}
      </div>
    )
  }

  const classes = useStyles()
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Événéments</h4>
            <Button
              simple
              href="/faq#menu-7"
              target="_blank"
              rel="noopener, noreferrer"
              style={{
                right: 30,
                top: 10,
                position: "absolute",
                zIndex: 999,
                height: 25,
                backgroundColor: "transparent",
                color: "#494949",
              }}
            >
              <h6 style={{ fontSize: "1em" }}>Documentation</h6>
            </Button>
          </CardHeader>
          <CardBody>
            {event ? (
              <ReactTable
                filter={(x, y) => {
                  changeFilter(x, y)
                }}
                name="event"
                columns={[
                  {
                    Header: "Visuel",
                    Cell: (tableProps) => (
                      <div>
                        <img
                          alt=".."
                          style={{ height: 60 }}
                          src={
                            BASE_URL + "img/events/" + tableProps.row.original.cover
                          }
                        />
                      </div>
                    ),
                  },
                  { Header: "Nom", accessor: "ev_name" },
                  { Header: "établissment", accessor: "c_name" },
                  {
                    Header: "Description",
                    accessor: (d) => {
                      return d.details ? d.details.substring(0, 60) + "..." : null
                    },
                  },
                  { Header: "Début", accessor: "dt_from" },
                  { Header: "Fin", accessor: "dt_to" },
                  { Header: "Tarif\\promo", accessor: "tarif" },
                  {
                    id: "suspended",
                    Header: "Suspendu",
                    accessor: (d) => (d.suspended ? "oui" : "non"),
                  },
                  { Header: "Visite", accessor: "visits" },
                  { Header: "Draft", accessor: "draft" },
                  { Header: "Actions", accessor: "actions" },
                ]}
                SubComponent={(row) => {
                  return <div>PICTURES IN ROW</div>
                }}
                data={event}
              />
            ) : null}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

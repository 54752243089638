import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useAlert } from "react-alert"
import { confirmAlert } from "react-confirm-alert"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import Assignment from "@material-ui/icons/Assignment"
import Dvr from "@material-ui/icons/Dvr"

// Private core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardBody from "components/Card/CardBody.js"
import CardIcon from "components/Card/CardIcon.js"
import CardHeader from "components/Card/CardHeader.js"
import ReactTable from "components/ReactTable/ReactTable.js"

import { dataTable } from "variables/general.js"

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js"
import {
  DeleteForever,
  ImportExport,
  RemoveCircle,
  RemoveRedEye,
} from "@material-ui/icons"

//Context &API
import authContext from "../../../context/AuthContext"
import {
  companyList,
  removeCompanyAndUser,
  suspendCompany,
} from "../../../api/companyAction"
import { parseBool } from "../../../utils/functions"
import config from "../../../config"

// Styling
import "react-confirm-alert/src/react-confirm-alert.css"
import { getFilter } from "../../../services/LocaleStorage"
import { Circle } from "@chakra-ui/react"
import Check from "@material-ui/icons/Check"
import { isNumber } from "underscore"

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
}

const label = {
  messageOK: "Bravo, modification effectuée !",
  messageKO: "Aie, veuillez réessayer ou contacter l'administrateur.",
}
const BASE_URL = config.BASE_URL
const useStyles = makeStyles(styles)

export function CompanyList() {
  const { mpId } = useContext(authContext)
  const history = useHistory()
  const alert = useAlert()

  const [data, setData] = useState(undefined)
  // const [data, setData] = useState(
  //   events.map((p, key) => {
  //     return {
  //       id: key,
  //       name: p.name,
  //       description: p.detail,
  //       city: p.city,
  //       phone: p.phone,
  //       suspended: p.suspended,
  //       actions: (
  //         // we've added some custom button actions
  //         <div className="actions-right">
  //           {/* use this button to add a like kind of action */}
  //           {/*<Button*/}
  //           {/*  justIcon*/}
  //           {/*  round*/}
  //           {/*  simple*/}
  //           {/*  onClick={() => {*/}
  //           {/*    let obj = data.find((o) => o.id === key)*/}
  //           {/*    alert(*/}
  //           {/*      "You've clicked LIKE button on \n{ \nName: " +*/}
  //           {/*        obj.name +*/}
  //           {/*        ", \nposition: " +*/}
  //           {/*        obj.position +*/}
  //           {/*        ", \noffice: " +*/}
  //           {/*        obj.office +*/}
  //           {/*        ", \nage: " +*/}
  //           {/*        obj.age +*/}
  //           {/*        "\n}."*/}
  //           {/*    )*/}
  //           {/*  }}*/}
  //           {/*  color="info"*/}
  //           {/*  className="like"*/}
  //           {/*>*/}
  //           {/*  <Favorite />*/}
  //           {/*</Button>{" "}*/}
  //           {/* use this button to add a edit kind of action */}
  //           <Button
  //             justIcon
  //             round
  //             simple
  //             onClick={() => {
  //               let obj = data.find((o) => o.id === key)
  //               alert(
  //                 "You've clicked EDIT button on \n{ \nName: " +
  //                   obj.name +
  //                   ", \nposition: " +
  //                   obj.position +
  //                   ", \noffice: " +
  //                   obj.office +
  //                   ", \nage: " +
  //                   obj.age +
  //                   "\n}."
  //               )
  //             }}
  //             color="warning"
  //             className="edit"
  //           >
  //             <Dvr />
  //           </Button>{" "}
  //           {/* use this button to remove the data row */}
  //           <Button
  //             justIcon
  //             round
  //             simple
  //             onClick={() => {
  //               var newData = data
  //               newData.find((o, i) => {
  //                 if (o.id === key) {
  //                   // here you should add some custom code so you can delete the data
  //                   // from this component and from your server as well
  //                   newData.splice(i, 1)
  //                   return true
  //                 }
  //                 return false
  //               })
  //               setData([...newData])
  //             }}
  //             color="danger"
  //             className="remove"
  //           >
  //             <Close />
  //           </Button>{" "}
  //         </div>
  //       ),
  //     }
  //   })
  // )

  useEffect(() => {
    if (mpId)
      companyList(mpId)
        .then((response) => {
          if (response.status === 200 && response.data.status === 1) {
            let st = response.data.data
            if (st.length > 0) {
              setData(
                st.map((v, k) => {
                  let isComplete = false
                  // prepare data for actions buttons (!)
                  if (
                    v.email &&
                    v.latitude &&
                    v.longitude &&
                    v.working_hours &&
                    v.latitude >= 0 &&
                    v.longitude >= 0
                  ) {
                    isComplete = true
                  } else {
                    console.log(
                      v.email,
                      isNumber(v.latitude),
                      isNumber(v.longitude),
                      v.working_hours,
                      v.latitude >= 0,
                      v.longitude >= 0
                    )
                  }
                  return {
                    id: v.id,
                    name: v.name,
                    city: v.city,
                    latitude: v.latitude,
                    longitude: v.longitude,
                    email: v.email,
                    lastname: v.lastname,
                    is_active: parseBool(v.is_active),
                    working_hours: v.working_hours,
                    is_complete: isComplete,
                    actions: actionButton(st, v.id, v.is_active, isComplete),
                  }
                })
              )
            }
          }
        })
        .catch((e) => console.log(e))
  }, [mpId])

  /**
   * Note : Delete company and associted user from db
   * @param _newData
   * @param _id
   * @param _companyName
   */
  const handleDelete = (_newData, _id, _companyName) => {
    confirmAlert({
      title: "Confirmer",
      message: `êtes vous sûr de vouloir supprimer ${_companyName} ?`,
      buttons: [
        {
          label: "Oui",
          onClick: () => {
            removeCompanyAndUser(_id)
              .then((response) => {
                setData([..._newData])
                alert.success(label.messageOK)
              })
              .catch((e) => {
                console.log(e)
                alert.error(label.messageKO)
              })
          },
        },
        {
          label: "Non",
          onClick: () => {},
        },
      ],
    })
  }

  /**
   * Note : action button for deleting and editing
   * @param _company
   * @param _id
   * @param _isActive
   * @returns {JSX.Element}
   */
  const actionButton = (_company, _id, _isActive = true, isComplete = true) => {
    return (
      <div className="actions-right">
        <Button
          justIcon
          round
          simple
          onClick={() => {
            console.log("inside in click", _company, _id)
            let obj = _company.find((o) => o.id === _id)
            if (obj) {
              const path = "/admin/company-manager"
              history.push({
                pathname: path,
                state: { cid: obj.id },
              })
            } else alert("nothing found : " + obj)
          }}
          color="info"
          className="edit"
        >
          <Dvr />
        </Button>{" "}
        <Button
          justIcon
          round
          simple
          onClick={() => {
            let obj = _company.find((o) => o.id === _id)
            if (obj) {
              suspendCompany(_id)
                .then((response) => {
                  alert.success(label.messageOK)
                  const updatedData = _company.map((x) =>
                    x.id === _id
                      ? { ...x, is_active: x.is_active === "0" ? "1" : "0" }
                      : x
                  )
                  updatedData.map((obj) => {
                    obj.actions = actionButton(updatedData, obj.id, obj.is_active)
                  })
                  setData([...updatedData])
                })
                .catch((e) => {
                  console.log(e)
                  alert.error(label.messageKO)
                })
            } else alert("nothing found : " + obj)
          }}
          color={parseBool(_isActive) ? "success" : "warning"}
          className={parseBool(_isActive) ? "success" : "warning"}
        >
          <RemoveCircle />
        </Button>{" "}
        {/* use this button to remove the data row */}
        <Button
          justIcon
          round
          simple
          onClick={() => {
            let newData = _company
            let companyName = ""
            newData.find((o, i) => {
              if (o.id === _id) {
                newData.splice(i, 1)
                companyName = o.name
                return true
              }
              return false
            })

            newData.map((obj) => {
              obj.actions = actionButton(newData, obj.id)
            })
            // Removing company and user from API _id === _cid
            handleDelete(newData, _id, companyName)
          }}
          color="danger"
          className="remove"
        >
          <DeleteForever />
        </Button>{" "}
        <Button
          justIcon
          round
          simple
          onClick={() => {
            let newData = _company
            newData.find((o, i) => {
              if (o.id === _id) {
                return true
              }
              return false
            })
          }}
          color={isComplete ? "success" : "warning"}
          className="edit"
        >
          <Check />
        </Button>
      </div>
    )
  }

  /**
   * Note : rendering
   */
  const classes = useStyles()
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            <CardIcon color="primary">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Sociétés</h4>
            <Button
              simple
              href="/faq#menu-3"
              target="_blank"
              rel="noopener, noreferrer"
              style={{
                right: 30,
                top: 10,
                position: "absolute",
                zIndex: 999,
                height: 25,
                backgroundColor: "transparent",
                color: "#494949",
              }}
            >
              <h6 style={{ fontSize: "1em" }}>Documentation</h6>
            </Button>
          </CardHeader>
          <CardBody>
            {data ? (
              <ReactTable
                name="company"
                columns={[
                  {
                    Header: "Visuel",
                    Cell: (tableProps) => (
                      <div>
                        <img
                          alt=".."
                          style={{ height: 60 }}
                          src={
                            BASE_URL +
                            "img/company/cover-" +
                            tableProps.row.original.id +
                            ".jpg"
                          }
                        />
                      </div>
                    ),
                  },
                  {
                    Header: "Société",
                    accessor: "name",
                  },
                  {
                    Header: "Responsable",
                    accessor: "lastname",
                  },
                  {
                    Header: "Ville",
                    accessor: "city",
                  },
                  {
                    Header: "Email",
                    accessor: "email",
                  },
                  // {
                  //   id: "suspended",
                  //   Header: "Suspendu",
                  //   accessor: (d) => (d.suspended ? "oui" : "non"),
                  // },
                  {
                    Header: "Actions",
                    accessor: "actions",
                  },
                ]}
                data={data}
              />
            ) : null}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

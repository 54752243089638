/*eslint-disable*/
import React, { useEffect, useState } from "react"
import isEmail from "validator/lib/isEmail"
import isURL from "validator/lib/isURL"
import ClipLoader from "react-spinners/ClipLoader"
import { GridLoader } from "react-spinners"
import isMobilePhone from "validator/es/lib/isMobilePhone"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import FormLabel from "@material-ui/core/FormLabel"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import InputAdornment from "@material-ui/core/InputAdornment"
import Radio from "@material-ui/core/Radio"
import Checkbox from "@material-ui/core/Checkbox"

// @material-ui/icons
import { Box, TextareaAutosize, TextField } from "@material-ui/core"
import MailOutline from "@material-ui/icons/MailOutline"
import Check from "@material-ui/icons/Check"
import Clear from "@material-ui/icons/Clear"
import Contacts from "@material-ui/icons/Contacts"
import FiberManualRecord from "@material-ui/icons/FiberManualRecord"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardIcon from "components/Card/CardIcon.js"
import CardBody from "components/Card/CardBody.js"

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js"
import buttonStyles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js"

import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import ImageUpload from "../../../components/CustomUpload/ImageUpload"
import customSelectStyle from "../../../assets/jss/material-dashboard-pro-react/customSelectStyle"
import customCheckboxRadioSwitch from "../../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch"
import Favorite from "@material-ui/icons/Favorite"
import {
  AlternateEmail,
  Apartment,
  BusinessCenter,
  LocationCity,
  MyLocation,
  People,
  Phone,
  Web,
} from "@material-ui/icons"
import Email from "@material-ui/icons/Email"
import Language from "@material-ui/icons/Language"

// Private Component
import {
  companyDetails,
  companyList,
  createCompany,
  getBusinessType,
  updateUserCompany,
} from "../../../api/companyAction"
import config from "../../../config"

const localStyles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
}

const disabledStyle = {
  disabledBack: {
    backgroundColor: "red",
  },
}

const useDisabledStyle = makeStyles(disabledStyle)
const useButtonStyles = makeStyles(buttonStyles)
const useLocalStyle = makeStyles(localStyles)
const useStyles = makeStyles(styles)
const useStylesExtended = makeStyles(stylesExtended)

const BASE_URL = config.BASE_URL

const label = {
  formTitle: "Génération d'établissement(s)",
  visuel: "Visuel",
  company: "société",
  companies: "Choisir un ou plusieurs établissements",
  name: "Nom du contact",
  description: "description",
  suspended: "Suspendu ?",
  image: "Image de l'établissement",
  site: "site internet",
  phone: "téléphone",
  save: "Enregistrer",
  latitude: "Latitude",
  longitude: "Longitude",
  address: "Adresse",
}

const ALERT_COLOR = "coral"

export function CompanyGenerationManager(props) {
  const [checked, setChecked] = useState([24, 22])
  const [selectedEnabled, setSelectedEnabled] = useState("b")
  const [selectedValue, setSelectedValue] = useState(null)
  const [simpleSelect, setSimpleSelect] = useState("")
  const [actif, setActif] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("test error message")
  const [errorMessageShowing, setErrorMessageShowing] = useState(false)
  const [businessType, setBusinessType] = useState(undefined)
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirm_password: "",
    companyName: "",
    website: "",
    phone: "",
    address: "",
    latitude: "",
    longitude: "",
    detail: "",
    username: "",
    cp: "",
    city: "",
    country: "",
    validConditions: "",
    cover: "",
    businessType: 0,
  })
  const [cid] = useState(props.location.state?.cid ?? undefined)
  const [loading, setLoading] = useState(true)
  const [imageCompany, setImageCompany] = useState(undefined)
  const [imagesData, setImagesData] = useState()
  const [info, setInfo] = useState({
    firstName: "green",
    lastName: "#495057",
    email: "green",
    password: "green",
    confirm_password: "green",
    companyName: "#495057",
    website: "#495057",
    phone: "green",
    address: "green",
    latitude: "#495057",
    longitude: "#495057",
    detail: "green",
    username: "green",
    cp: "green",
    city: "green",
    country: "green",
    validConditions: "green",
    cover: "green",
    businessType: "green",
  })
  const [check, setCheck] = useState({
    check_companyMissing: false,
    check_emailMissing: false,
    check_lastNameMissing: false,
    check_coordsMissing: false,
    check_phoneMissing: false,
    check_emailInputChange: true,
    check_textInputChange: false,
    secureTextEntry: true,
    confirm_secureTextEntry: true,
  })

  /**
   * Note : Get USER DATA
   */
  useEffect(() => {
    if (cid)
      companyDetails(cid)
        .then((response) => {
          if (typeof response.data !== "undefined") {
            if (response.status === 200) {
              let x = response.data
              setData({
                ...data,
                firstName: x.firstname,
                lastName: x.lastname,
                email: x.email,
                password: "",
                confirm_password: "",
                companyName: x.name,
                website: x.website ?? "",
                phone: x.phone || "",
                address: x.address,
                latitude: x.latitude,
                longitude: x.longitude,
                detail: x.detail,
                username: x.username,
                cp: x.cp,
                city: x.city,
                country: x.country,
                validConditions: x.validConditions,
                cover: x.iscover,
                businessType: x.businessType.id ?? 0,
              })
              setLoading(false)
            }
          }
        })
        .catch((e) => {
          console.log("catch", e.response.status, e.response.data.message)
          /** Manage problem with token
           * - Expired JWT Token
           * - Invalid JWT Token
           */
          // if (e.response.status === 401) {
          //   setReconnect(true)
          // }
        })
    else setLoading(false)
  }, [cid])

  /**
   * Note :  Prevent bad value
   */
  useEffect(() => {}, [data])

  /**
   * Note : Fill The Businee Type Selector
   */
  useEffect(() => {
    getBusinessType()
      .then((response) => {
        if (typeof response.data !== "undefined") {
          if (response.status >= 200 && response.status <= 299) {
            let st = response.data.map(({ id, name, icon, ...rest }) => ({
              key: id,
              label: name,
              icon: icon,
            }))
            setBusinessType(st)
          }
        }
      })
      .catch((e) => console.log(e))
  }, [])

  /**
   * Temporary set default businesstype
   */
  useEffect(() => {
    if (businessType) {
      console.log(businessType[0])
      setData({ ...data, businessType: 0 })
    }
  }, [businessType])

  /**
   * Note : reloading image each time...
   */
  useEffect(() => {
    if (cid)
      setImageCompany(
        BASE_URL + "img/company/cover-" + cid + ".jpg?date=" + new Date().getTime()
      )
  }, [cid])

  /**
   * Note: management of simple input
   * @param _text
   * @param _fromWich
   */
  const handleInputChange = (_text, _fromWich) => {
    setData({ ...data, [_fromWich]: _text })

    switch (_fromWich) {
      case "companyName":
        setInfo({ ...info, [_fromWich]: _text ? "#495057" : "coral" })
        break
      case "lastName":
        setInfo({ ...info, [_fromWich]: _text ? "#495057" : "coral" })
        break
      case "email":
        if (isEmail(_text)) {
          setInfo({ ...info, [_fromWich]: "green" })
        } else {
          setInfo({ ...info, [_fromWich]: "coral" })
        }
        break
      case "phone":
        if (isMobilePhone(_text)) {
          setInfo({ ...info, [_fromWich]: "green" })
        } else {
          setInfo({ ...info, [_fromWich]: "coral" })
        }
        break
      case "website":
        if (isURL(_text)) {
          setInfo({ ...info, [_fromWich]: "green" })
        } else {
          setInfo({ ...info, [_fromWich]: "coral" })
        }
        break
      case "longitude":
        setInfo({
          ...info,
          [_fromWich]: _text >= -180 && _text <= 180 ? "#495057" : "coral",
        })
        break
      case "latitude":
        setInfo({
          ...info,
          [_fromWich]: _text >= -90 && _text <= 90 ? "#495057" : "coral",
        })
        break
    }
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }
  const handleSimple = (event) => {
    setSimpleSelect(event.target.value)
  }
  const handleChangeEnabled = (event) => {
    setSelectedEnabled(event.target.value)
  }
  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  /**
   * ###############################################################################
   * Saving the data                      handleRegister
   * ###############################################################################
   * */
  const handleRegister = () => {
    let showAlert = false
    console.log("before", cid)

    if (data.companyName.length === 0) {
      setInfo((prevState) => {
        return { ...prevState, companyName: ALERT_COLOR }
      })
      showAlert = true
    }
    if (data.email.length === 0) {
      console.log("email")
      setInfo((prevState) => {
        return { ...prevState, email: ALERT_COLOR }
      })
      showAlert = true
    }

    if (showAlert === false) {
      setLoading(true)
      console.log(data)
      // Updating text data
      if (cid) {
        updateUserCompany(data, cid)
          .then((response) => {
            setLoading(false)
            alert("Update ok")
          })
          .catch((e) => {
            console.log(e)
            alert("update failure")
            setLoading(false)
          })
        // Updating image if necessary
        // if (isNewImage) {
        //   uploadImageCompany(cid, imagesData)
        //     .then((response) => {
        //       setLoading(false)
        //     })
        //     .catch((e) => {
        //       console.log(e)
        //       alert("Update image failure")
        //       setLoading(false)
        //     })
        // }}
      } else {
        console.log("inserting...")
        // Insert a new Company
        // Mandatory data (company name, email, user password will be generated auto)
        createCompany(data, 1)
          .then((response) => {
            console.log(response)
            setLoading(false)
          })
          .catch((e) => {
            console.log(e)
            setLoading(false)
          })
      }
    } else {
      // Information about the errors or missing data
      setErrorMessage("Veuillez vérifier vos données")
      setErrorMessageShowing(true)
      setTimeout(() => {
        setErrorMessageShowing(false)
      }, 6000)
    }
  }

  // Style construction (multi theme options...)
  const buttonClasses = useButtonStyles()
  const classes = useStyles()
  const classesExtended = useStylesExtended()
  const classesLocal = useLocalStyle()
  const disabled = useDisabledStyle()

  return (
    <div>
      {!loading ? (
        <GridContainer>
          {/*Large Form*/}
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>{label.formTitle}</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <form>
                  <GridContainer
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                  >
                    <GridItem xs={12} sm={6}>
                      <CustomInput
                        labelText={label.company}
                        id="companyName"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Apartment style={{ color: info.companyName }} />
                            </InputAdornment>
                          ),
                          type: "text",
                          value: data?.companyName,
                          onChange: (e) => {
                            handleInputChange(e.target.value, "companyName")
                          },
                        }}
                      />
                      <CustomInput
                        labelText={label.name}
                        id="lastname"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <People style={{ color: info.lastName }} />
                            </InputAdornment>
                          ),
                          type: "text",
                          value: data?.lastName,
                          onChange: (e) => {
                            handleInputChange(e.target.value, "lastName")
                          },
                        }}
                      />

                      {/** ################################################ **/}
                      {/** ####################     EMAIL     ############# **/}
                      {/** ################################################ **/}
                      <CustomInput
                        labelText="Email"
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AlternateEmail style={{ color: info.email }} />
                            </InputAdornment>
                          ),
                          type: "email",
                          value: data?.email,
                          onChange: (e) => {
                            handleInputChange(e.target.value, "email")
                          },
                        }}
                      />
                      {/*<CustomInput*/}
                      {/*  labelText="Mot de passe"*/}
                      {/*  id="password"*/}
                      {/*  formControlProps={{*/}
                      {/*    fullWidth: true,*/}
                      {/*  }}*/}
                      {/*  inputProps={{*/}
                      {/*    type: "password",*/}
                      {/*    autoComplete: "off",*/}
                      {/*  }}*/}
                      {/*/>*/}

                      {/** ################################################ **/}
                      {/** ####################     PHONE     ############# **/}
                      {/** ################################################ **/}
                      <CustomInput
                        labelText="Téléphone"
                        id="phone"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Phone />
                            </InputAdornment>
                          ),
                          type: "text",
                          value: data?.phone,
                          onChange: (e) => {
                            handleInputChange(e.target.value, "phone")
                          },
                        }}
                      />

                      {/** ################################################ **/}
                      {/** ####################     SITE      ############# **/}
                      {/** ################################################ **/}
                      <CustomInput
                        labelText={label.site}
                        id="website"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Language style={{ color: info.website }} />
                            </InputAdornment>
                          ),
                          type: "text",
                          value: data?.website,
                          onChange: (e) => {
                            handleInputChange(e.target.value, "website")
                          },
                        }}
                      />

                      {/** ################################################ **/}
                      {/** ####################     ADDRESS   ############# **/}
                      {/** ################################################ **/}
                      <CustomInput
                        labelText={label.address}
                        id="address"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <LocationCity />
                            </InputAdornment>
                          ),
                          type: "text",
                          value: data?.address,
                          onChange: (e) => {
                            handleInputChange(e.target.value, "address")
                          },
                        }}
                      />

                      {/** ################################################ **/}
                      {/** ####################     GEOLOC    ############# **/}
                      {/** ################################################ **/}
                      <GridContainer>
                        <GridItem sm={6} xs={12}>
                          <CustomInput
                            labelText={label.latitude}
                            id="latitude"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <MyLocation style={{ color: info.latitude }} />
                                </InputAdornment>
                              ),
                              type: "number",
                              value: data?.latitude,
                              onChange: (e) => {
                                handleInputChange(e.target.value, "latitude")
                              },
                            }}
                          />
                        </GridItem>
                        <GridItem sm={6} xs={12}>
                          <CustomInput
                            labelText={label.longitude}
                            id="longitude"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <MyLocation style={{ color: info.longitude }} />
                                </InputAdornment>
                              ),
                              type: "number",
                              value: data?.longitude,
                              onChange: (e) => {
                                handleInputChange(e.target.value, "longitude")
                              },
                            }}
                          />
                        </GridItem>
                      </GridContainer>

                      {/** ################################################ **/}
                      {/** ####################     DETAIL    ############# **/}
                      {/** ################################################ **/}
                      <TextField
                        id="detail"
                        label="Description"
                        placeholder="Description"
                        multiline
                        style={{ width: "100%" }}
                        value={data.detail}
                        onChange={(e) => {
                          handleInputChange(e.target.value, "detail")
                        }}
                      />
                    </GridItem>

                    {/** ################################################ **/}
                    {/** ####################  VISUEL       ############# **/}
                    {/** ################################################ **/}
                    <GridItem xs={12} sm={4}>
                      <GridContainer
                        container
                        spacing={1}
                        direction="column"
                        alignItems="center"
                        className={buttonClasses.marginTop}
                      >
                        <legend>Image de l'établissement</legend>
                        <ImageUpload
                          url={imageCompany}
                          addButtonProps={{
                            color: "rose",
                            round: true,
                          }}
                          changeButtonProps={{
                            color: "rose",
                            round: true,
                          }}
                          removeButtonProps={{
                            color: "danger",
                            round: true,
                          }}
                        />
                      </GridContainer>
                    </GridItem>
                    <GridContainer
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                    >
                      <GridItem className={buttonClasses.marginTop}>
                        <label style={{ color: "coral" }}>{errorMessage}</label>
                      </GridItem>
                      <GridItem>
                        <Button
                          color="success"
                          round
                          onClick={() => {
                            handleRegister()
                          }}
                        >
                          <Favorite className={buttonClasses.icons} /> Enregistrer
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : (
        <div className={disabled.disabledBack}>
          <GridLoader
            color="#3f4850"
            loading={loading}
            size={50}
            css={`
              position: absolute;
              display: block;
              z-index: 999;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            `}
          />
        </div>
      )}
    </div>
  )
}

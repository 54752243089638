/*eslint-disable*/
import React, { useContext, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import isEmail from "validator/lib/isEmail"
import isURL from "validator/lib/isURL"
import isMobilePhone from "validator/es/lib/isMobilePhone"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js"
import { GridLoader } from "react-spinners"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import FormLabel from "@material-ui/core/FormLabel"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import InputAdornment from "@material-ui/core/InputAdornment"
import Radio from "@material-ui/core/Radio"
import Checkbox from "@material-ui/core/Checkbox"

// @material-ui/icons
import { Box, TextareaAutosize, TextField } from "@material-ui/core"
import MailOutline from "@material-ui/icons/MailOutline"
import Check from "@material-ui/icons/Check"
import Clear from "@material-ui/icons/Clear"
import Contacts from "@material-ui/icons/Contacts"
import FiberManualRecord from "@material-ui/icons/FiberManualRecord"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"

// core components
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import Button from "components/CustomButtons/Button.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardIcon from "components/Card/CardIcon.js"
import CardBody from "components/Card/CardBody.js"

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle"
import stylesExtended from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js"
import buttonStyles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js"

import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import ImageUpload from "../../components/CustomUpload/ImageUpload"
import customSelectStyle from "../../assets/jss/material-dashboard-pro-react/customSelectStyle"
import customCheckboxRadioSwitch from "../../assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch"
import Favorite from "@material-ui/icons/Favorite"
import ArrowBack from "@material-ui/icons/ArrowBack"

import {
  AlternateEmail,
  Apartment,
  BusinessCenter,
  InsertPhoto,
  LocationCity,
  MyLocation,
  People,
  Phone,
  Web,
} from "@material-ui/icons"
import Email from "@material-ui/icons/Email"
import Language from "@material-ui/icons/Language"

// Private Component
import JAlert from "../../components/smallComponent/jAlert"

// Context & API
import authContext from "../../context/AuthContext"
import {
  companyDetails,
  companyList,
  createCompany,
  getBusinessType,
  updateUserCompany,
  uploadImageCompany,
} from "../../api/companyAction"
import config from "../../config"
import CustomGooglePlace from "../../components/CustomGooglePlace/CustomGooglePlace"
import CropperX from "../../components/CropperX"
import CustomPhoneInput from "../../components/CustomPhoneInput/CustomPhoneInput"
import WorkingHours from "../../utils/hours/workingHours"

const localStyles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px",
  },
  backButton: {
    right: 30,
    position: "fixed",
    zIndex: 999,
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch,
}

const disabledStyle = {
  disabledBack: {
    backgroundColor: "red",
  },
}

const useDisabledStyle = makeStyles(disabledStyle)
const useButtonStyles = makeStyles(buttonStyles)
const useLocalStyle = makeStyles(localStyles)
const useStyles = makeStyles(styles)
const useStylesExtended = makeStyles(stylesExtended)

const BASE_URL = config.BASE_URL

const label = {
  formTitle: "Détails du nouvel établissement",
  visuel: "Visuel",
  company: "Nom de cet établissement*",
  companies: "Choisir un ou plusieurs établissements",
  name: "Nom du contact de cet établissement (visible en interne seulement)",
  description: "Description de cet établissement",
  suspended: "Suspendu ?",
  image: "Image de l'établissement",
  companyPic: "Image de l'établissement",
  site: "Site internet de cet établissement (sinon du siège)",
  phone: "Téléphone de cet établissement (obligatoire sinon un autre)*",
  save: "Enregistrer",
  latitude: "Latitude",
  longitude: "Longitude",
  address: "Adresse",
  email: "Mail du contact de cet établissement (visible en interne seulement)*",
  visuelChoice: "Photo de la devanture de cet établissement",
  ajouterBtn: "Ajouter",
  modifierBtn: "Modifier",
  modifierAndExitBtn: "Modifier et sortir",
  back: "retour",
  openingHours: "Horaire d'ouverture",
}

const ALERT_COLOR = "coral"
const placeHolder = require("assets/img/placeholder.jpg")

export function CompanyManager(props) {
  const { mpId } = useContext(authContext)
  const inputRef = useRef(null)
  const history = useHistory()
  const [showCropper, setShowCropper] = useState(false)
  const [base64Image, setBase64Image] = useState(undefined)
  const [isNewImage, setIsNewImage] = useState(false)
  const [checked, setChecked] = useState([24, 22])
  const [selectedEnabled, setSelectedEnabled] = useState("b")
  const [selectedValue, setSelectedValue] = useState(null)
  const [simpleSelect, setSimpleSelect] = useState("")
  const [actif, setActif] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("Une erreur est survenue")
  const [errorMessageShowing, setErrorMessageShowing] = useState(false)
  const [businessType, setBusinessType] = useState(undefined)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [coord, setCoord] = useState({ latitude: undefined, longitude: undefined })
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirm_password: "",
    companyName: "",
    website: "",
    phone: "",
    address: "",
    latitude: "",
    longitude: "",
    detail: "",
    username: "",
    cp: "",
    city: "",
    country: "",
    validConditions: "",
    cover: "",
    businessType: 0,
    workingHours: undefined,
  })
  const [cid] = useState(props.location.state?.cid ?? undefined)
  const [loading, setLoading] = useState(true)
  const [currentImage, setCurrentImage] = useState(undefined)
  const [imageCompany, setImageCompany] = useState(undefined)
  const [imagesData, setImagesData] = useState()
  const [info, setInfo] = useState({
    firstName: "green",
    lastName: "#495057",
    email: "green",
    password: "green",
    confirm_password: "green",
    companyName: "#495057",
    website: "#495057",
    phone: "green",
    address: "green",
    latitude: "#495057",
    longitude: "#495057",
    detail: "green",
    username: "green",
    cp: "green",
    city: "green",
    country: "green",
    validConditions: "green",
    cover: "green",
    businessType: "green",
    workingHours: "green",
  })
  const [check, setCheck] = useState({
    check_companyMissing: false,
    check_emailMissing: false,
    check_lastNameMissing: false,
    check_coordsMissing: false,
    check_phoneMissing: false,
    check_emailInputChange: true,
    check_textInputChange: false,
    secureTextEntry: true,
    confirm_secureTextEntry: true,
  })
  const [showAlert, setShowAlert] = useState(false)
  const [typeAlert, setTypeAlert] = useState("success")
  const [alertMessageBox, setAlertMessageBox] = useState(
    "Bravo, vos modifications ont été enregistrées."
  )

  /**
   * Note : Get USER DATA
   */
  useEffect(() => {
    if (cid)
      companyDetails(cid)
        .then((response) => {
          // console.log(response)
          if (typeof response.data !== "undefined") {
            if (response.status === 200) {
              let x = response.data
              setData({
                ...data,
                firstName: x.firstname,
                lastName: x.lastname,
                email: x.email,
                password: "",
                confirm_password: "",
                companyName: x.name,
                website: x.website ?? "",
                phone: x.phone || "",
                address: x.address,
                latitude: x.latitude,
                longitude: x.longitude,
                detail: x.detail,
                username: x.username,
                cp: x.cp,
                city: x.city,
                country: x.country,
                validConditions: x.validConditions,
                cover: x.iscover,
                businessType: x.businessType.id ?? 0,
                workingHours: x.workingHours ?? undefined,
              })
              setCurrentImage(BASE_URL + "img/company/cover-" + cid + ".jpg")
              setEditorState(
                EditorState.createWithContent(
                  ContentState.createFromBlockArray(convertFromHTML(x.detail ?? ""))
                )
              )
              setLoading(false)
            }
          }
        })
        .catch((e) => {
          console.log("catch", e.response.status, e.response.data.message)
        })
    else setLoading(false)
  }, [cid])

  /**
   * Note : Fill The Business Type Selector
   */
  useEffect(() => {
    getBusinessType()
      .then((response) => {
        if (typeof response.data !== "undefined") {
          if (response.status >= 200 && response.status <= 299) {
            let st = response.data.map(({ id, name, icon, ...rest }) => ({
              key: id,
              label: name,
              icon: icon,
            }))
            setBusinessType(st)
          }
        }
      })
      .catch((e) => console.log(e))
  }, [])

  /**
   * Temporary set default businesstype
   */
  useEffect(() => {
    if (businessType) {
      setData({ ...data, businessType: 0 })
    }
  }, [businessType])

  /**
   * Note : reloading image each time...
   */
  useEffect(() => {
    if (cid)
      setImageCompany(
        BASE_URL + "img/company/cover-" + cid + ".jpg?date=" + new Date().getTime()
      )
  }, [cid])

  /**
   * Note : astuce to update data.latitude and longitude
   */
  // useEffect(() => {
  //   setData((prevState) => {
  //     return { ...prevState, latitude: coord.latitude, longitude: coord.longitude }
  //   })
  // }, [coord])

  /**
   * Note : feedback return from Google autocomple
   * @param _lat
   * @param _lng
   * @param _add
   * @param _city
   * @param _cp
   * @param _country
   */
  const handleCoordChange = (
    _lat,
    _lng,
    _add,
    _city = undefined,
    _cp = undefined,
    _country = undefined
  ) => {
    setCoord({
      ...coord,
      latitude: _lat,
      longitude: _lng,
    })
    setData((prevState) => ({
      ...prevState,
      latitude: _lat,
      longitude: _lng,
      address: _add,
      city: _city,
      cp: _cp,
      country: _country,
    }))

    // setData({
    //   ...data,
    //   latitude: _lat,
    //   longitude: _lng,
    //   address: _add,
    //   city: _city,
    //   cp: _cp,
    // })
  }

  /**
   * Note: management of simple input
   * @param _text
   * @param _fromWich
   */
  const handleInputChange = (_text, _fromWich) => {
    if (_text) {
      setData({ ...data, [_fromWich]: _text })
      switch (_fromWich) {
        case "companyName":
          setInfo({ ...info, [_fromWich]: _text ? "#495057" : "coral" })
          break
        case "lastName":
          setInfo({ ...info, [_fromWich]: _text ? "#495057" : "coral" })
          break
        case "email":
          if (isEmail(_text)) {
            setInfo({ ...info, [_fromWich]: "green" })
          } else {
            setInfo({ ...info, [_fromWich]: "coral" })
          }
          break
        case "phone":
          if (isMobilePhone(_text)) {
            setInfo({ ...info, [_fromWich]: "green" })
          } else {
            setInfo({ ...info, [_fromWich]: "coral" })
          }
          break
        case "website":
          if (isURL(_text)) {
            setInfo({ ...info, [_fromWich]: "green" })
          } else {
            setInfo({ ...info, [_fromWich]: "coral" })
          }
          break
        case "longitude":
          setInfo({
            ...info,
            [_fromWich]: _text >= -180 && _text <= 180 ? "#495057" : "coral",
          })
          break
        case "latitude":
          setInfo({
            ...info,
            [_fromWich]: _text >= -90 && _text <= 90 ? "#495057" : "coral",
          })
          break
      }
    } else {
      setData({ ...data, [_fromWich]: _text })
    }
  }

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
  }
  const handleSimple = (event) => {
    setSimpleSelect(event.target.value)
  }
  const handleChangeEnabled = (event) => {
    setSelectedEnabled(event.target.value)
  }
  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  /**
   * Note: redirection after validation message
   */
  const redirect = () => {
    const path = "/admin/company-list"
    history.push({
      pathname: path,
    })
  }

  /**
   * ###############################################################################
   * Saving the data                      handleRegister
   * ###############################################################################
   * */
  const handleRegister = () => {
    document.querySelector("body").scrollTo(0, 0)

    let showAlert = false
    setLoading(true)

    if (data.companyName.length === 0) {
      setInfo((prevState) => {
        return { ...prevState, companyName: ALERT_COLOR }
      })
      showAlert = true
    }
    if (data.email.length === 0) {
      setInfo((prevState) => {
        return { ...prevState, email: ALERT_COLOR }
      })
      showAlert = true
    }
    // if (data.workingHours.length === 0) {
    //   setInfo((prevState) => {
    //     return { ...prevState, workingHours: ALERT_COLOR }
    //   })
    //   showAlert = true
    // }

    if (showAlert === false) {
      // Updating text data
      if (cid) {
        updateUserCompany(data, cid)
          .then((response) => {
            setLoading(false)
            setShowAlert(true)
          })
          .catch((e) => {
            console.log(e)
            alert("Une erreur est survenue")
            setLoading(false)
          })
          .finally(() => {
            //Updating image if necessary
            if (isNewImage) {
              uploadImageCompany(cid, base64Image)
                .then((response) => {
                  setLoading(false)
                })
                .catch((e) => {
                  console.log(e)
                  alert("Update image failure")
                  setLoading(false)
                })
            }
          })
      } else {
        // Insert a new Company
        // Mandatory data (company name, email, user password will be generated auto)
        createCompany(data, mpId)
          .then((response) => {
            setLoading(false)
            if (response.data.status === 201) {
              setTypeAlert("success")
              setAlertMessageBox("Bravo, vos modifications ont été enregistrées.")
              setShowAlert(true)
              return response.data.cid
            } else {
              setTypeAlert("warning")
              setAlertMessageBox(response.data.message)
              setShowAlert(true)
            }
          })
          .then((newCid) => {
            if (isNewImage) {
              uploadImageCompany(newCid, base64Image)
                .then((response) => {
                  setLoading(false)
                })
                .catch((e) => {
                  console.log(e)
                  alert("Update image failure")
                  setLoading(false)
                })
            }
          })
          .catch((e) => {
            setLoading(false)
            alert("Une erreur est survenue")
          })
      }
    } else {
      // Information about the errors or missing data
      setErrorMessage("Veuillez vérifier vos données")
      setErrorMessageShowing(true)
      setTimeout(() => {
        setErrorMessageShowing(false)
        setLoading(false)
      }, 2000)
    }
  }

  /**
   * Note Get information from child component Working Hours
   * @param _data
   */
  const getPlanning = (_data) => {
    setData({ ...data, ["workingHours"]: _data })
  }

  /**
   * Note : Alert manager
   */
  const showInfo = () => {
    setShowAlert(!showAlert)
  }

  // Style construction (multi theme options...)
  const buttonClasses = useButtonStyles()
  const classes = useStyles()
  const classesExtended = useStylesExtended()
  const classesLocal = useLocalStyle()
  const disabled = useDisabledStyle()

  return (
    <div>
      {!loading ? (
        <GridContainer>
          {/*Large Form*/}
          <JAlert
            type={typeAlert}
            show={showAlert}
            localStatus={showInfo}
            feedBack={redirect}
            titleText={alertMessageBox}
            subTitleText={
              typeAlert === "success " ? "...et elles sont déjà actives." : ""
            }
          />
          <GridItem xs={12}>
            <Button
              style={localStyles.backButton}
              onClick={() => {
                redirect()
              }}
            >
              <ArrowBack className={buttonClasses.icons} /> {label.back}
            </Button>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>
                    {data.companyName
                      ? data.companyName
                      : "Détails du nouvel établissement"}
                  </h4>
                </CardText>
                <Button
                  simple
                  href="/faq#menu-4"
                  target="_blank"
                  rel="noopener, noreferrer"
                  style={{
                    height: 25,
                    backgroundColor: "transparent",
                    color: "#494949",
                  }}
                >
                  <h6 style={{ fontSize: "1em" }}>Documentation</h6>
                </Button>
              </CardHeader>

              <CardBody>
                <form>
                  <GridContainer
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                  >
                    <GridItem xs={12} sm={6}>
                      <CustomInput
                        labelText={label.company}
                        id="companyName"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Apartment style={{ color: info.companyName }} />
                            </InputAdornment>
                          ),
                          type: "text",
                          value: data?.companyName,
                          onChange: (e) => {
                            handleInputChange(e.target.value, "companyName")
                          },
                        }}
                      />
                      <CustomInput
                        labelText={label.name}
                        id="lastname"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <People style={{ color: info.lastName }} />
                            </InputAdornment>
                          ),
                          type: "text",
                          value: data?.lastName,
                          onChange: (e) => {
                            handleInputChange(e.target.value, "lastName")
                          },
                        }}
                      />
                      {/** ################################################ **/}
                      {/** ####################     EMAIL     ############# **/}
                      {/** ################################################ **/}
                      <CustomInput
                        labelText={label.email}
                        id="email"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <AlternateEmail style={{ color: info.email }} />
                            </InputAdornment>
                          ),
                          type: "email",
                          value: data?.email,
                          onChange: (e) => {
                            handleInputChange(e.target.value, "email")
                          },
                        }}
                      />
                      {/** ################################################ **/}
                      {/** ####################     PHONE     ############# **/}
                      {/** ################################################ **/}
                      {/*<CustomInput*/}
                      {/*  labelText="Téléphone"*/}
                      {/*  id="phone"*/}
                      {/*  formControlProps={{*/}
                      {/*    fullWidth: true,*/}
                      {/*  }}*/}
                      {/*  inputProps={{*/}
                      {/*    endAdornment: (*/}
                      {/*      <InputAdornment position="end">*/}
                      {/*        <Phone />*/}
                      {/*      </InputAdornment>*/}
                      {/*    ),*/}
                      {/*    type: "text",*/}
                      {/*    value: data?.phone,*/}
                      {/*    onChange: (e) => {*/}
                      {/*      handleInputChange(e.target.value, "phone")*/}
                      {/*    },*/}
                      {/*  }}*/}
                      {/*/>*/}
                      <CustomPhoneInput
                        labelText={label.phone}
                        id="phone"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        value={data?.phone}
                        phoneChange={(e) => {
                          handleInputChange(e, "phone")
                        }}
                      />
                      {/*<PhoneInput*/}
                      {/*  international*/}
                      {/*  countryCallingCodeEditable={false}*/}
                      {/*  placeholder="Téléphone"*/}
                      {/*  value={data?.phone}*/}
                      {/*  onChange={(e) => {*/}
                      {/*    handleInputChange(e, "phone")*/}
                      {/*  }}*/}
                      {/*/>*/}
                      {/** ################################################ **/}
                      {/** ####################     SITE      ############# **/}
                      {/** ################################################ **/}
                      <CustomInput
                        labelText={label.site}
                        id="website"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Language style={{ color: info.website }} />
                            </InputAdornment>
                          ),
                          type: "text",
                          value: data?.website,
                          onChange: (e) => {
                            handleInputChange(e.target.value, "website")
                          },
                        }}
                      />
                      {/** ################################################ **/}
                      {/** ####################     ADDRESS   ############# **/}
                      {/** ################################################ **/}
                      <CardBody
                        style={{ border: "2px solid beige", marginBottom: 20 }}
                      >
                        <CustomGooglePlace
                          labelText={label.address}
                          id="website"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          value={data?.address}
                          coord={(lat, lng, add, city, cp, country) => {
                            handleCoordChange(lat, lng, add, city, cp, country)
                          }}
                        />
                        {/** ################################################ **/}
                        {/** ####################     GEOLOC    ############# **/}
                        {/** ################################################ **/}
                        <GridContainer>
                          <GridItem sm={6} xs={12}>
                            <CustomInput
                              labelText={label.latitude}
                              id="latitude"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <MyLocation style={{ color: info.latitude }} />
                                  </InputAdornment>
                                ),
                                type: "number",
                                value: coord?.latitude ?? data?.latitude,
                                // onChange: (e) => {
                                //   handleInputChange(e.target.value, "latitude")
                                // },
                              }}
                            />
                          </GridItem>
                          <GridItem sm={6} xs={12}>
                            <CustomInput
                              labelText={label.longitude}
                              id="longitude"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <MyLocation style={{ color: info.longitude }} />
                                  </InputAdornment>
                                ),
                                type: "number",
                                value: coord?.longitude ?? data?.longitude,
                                // onChange: (e) => {
                                //   handleInputChange(e.target.value, "longitude")
                                // },
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                      {/** ################################################ **/}
                      {/** ####################  Details ################## **/}
                      {/** ################################################ **/}
                      <legend>{label.description}</legend>
                      <Editor
                        toolbar={{
                          options: [
                            "inline",
                            "blockType",
                            "fontSize",
                            "fontFamily",
                            "list",
                            "textAlign",
                            "link",
                            "embedded",
                            "emoji",
                            "history",
                          ],
                          inline: {
                            inDropdown: false,
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                            options: [
                              "bold",
                              "italic",
                              "underline",
                              "strikethrough",
                              "monospace",
                              "superscript",
                              "subscript",
                            ],
                          },
                        }}
                        editorState={editorState}
                        // toolbarClassName="toolbarClassName"
                        // wrapperClassName="demo-wrapper"
                        // editorClassName="demo-editor"
                        editorStyle={{
                          display: showCropper ? "none" : "block",
                          backgroundColor: "#dbdcdc",
                          height: "260px",
                        }}
                        onEditorStateChange={setEditorState}
                        onContentStateChange={() => {
                          let st = draftToHtml(
                            convertToRaw(editorState.getCurrentContent())
                          )
                          setData((prevState) => {
                            return { ...prevState, detail: st }
                          })
                        }}
                      />

                      {/** ################################################ **/}
                      {/** #################    Opening Hours    ########## **/}
                      {/** ################################################ **/}
                      <GridContainer
                        container
                        spacing={2}
                        direction="column"
                        alignItems="center"
                        style={{ marginTop: 40 }}
                      >
                        <GridItem xs={12} sm={12}>
                          <legend style={{ textAlign: "left" }}>
                            {label.openingHours}
                          </legend>
                          <div className={classesExtended.block}>
                            <WorkingHours
                              fieldName={"location[working_hours]"}
                              dataStr={data.workingHours}
                              backFire={(a) => getPlanning(a)}
                            />
                          </div>
                        </GridItem>
                      </GridContainer>
                    </GridItem>

                    {/** ################################################ **/}
                    {/** ####################  VISUEL       ############# **/}
                    {/** ################################################ **/}
                    <GridItem xs={12} sm={6} style={localStyles.centerIt}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={base64Image ?? currentImage ?? placeHolder.default}
                          alt="justnow"
                          style={{
                            height: 300,
                            boxShadow: "0 0 32px rgb(0 0 0 / 30%)",
                          }}
                        />
                        <Button
                          color="info"
                          round
                          className={buttonClasses.marginTop}
                          onClick={() => {
                            setShowCropper(!showCropper)
                          }}
                        >
                          <InsertPhoto className={buttonClasses.icons} />{" "}
                          {label.visuelChoice}
                        </Button>
                      </div>
                      {/*<GridContainer*/}
                      {/*  container*/}
                      {/*  spacing={1}*/}
                      {/*  direction="column"*/}
                      {/*  alignItems="center"*/}
                      {/*>*/}
                      {/*  <legend>Image de l'établissement</legend>*/}
                      {/*  <ImageUpload*/}
                      {/*    url={imageCompany}*/}
                      {/*    addButtonProps={{*/}
                      {/*      color: "rose",*/}
                      {/*      round: true,*/}
                      {/*    }}*/}
                      {/*    changeButtonProps={{*/}
                      {/*      color: "rose",*/}
                      {/*      round: true,*/}
                      {/*    }}*/}
                      {/*    removeButtonProps={{*/}
                      {/*      color: "danger",*/}
                      {/*      round: true,*/}
                      {/*    }}*/}
                      {/*  />*/}
                      {/*</GridContainer>*/}
                    </GridItem>
                    <GridContainer
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                    >
                      {/*<GridItem className={buttonClasses.marginTop}>*/}
                      {/*  <label style={{ color: "coral" }}>{errorMessage}</label>*/}
                      {/*</GridItem>*/}
                      <GridItem>
                        <Button
                          color="success"
                          round
                          onClick={() => {
                            handleRegister()
                          }}
                        >
                          <Favorite className={buttonClasses.icons} />{" "}
                          {cid ? label.modifierBtn : label.ajouterBtn}
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                </form>
              </CardBody>
            </Card>
          </GridItem>
          <CropperX
            visibility={showCropper}
            backInfo={() => setShowCropper(!showCropper)}
            title={label.companyPic}
            image={currentImage}
            b64={(x) => {
              setIsNewImage(true)
              setBase64Image(x)
            }}
            direction="row"
            cropAreaAspect={3 / 2}
          />
        </GridContainer>
      ) : (
        <div className={disabled.disabledBack}>
          <GridLoader
            color="#3f4850"
            loading={loading}
            size={50}
            css={`
              position: absolute;
              display: block;
              z-index: 999;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            `}
          />
          <JAlert
            type="success"
            show={showAlert}
            localStatus={showInfo}
            titleText="Bravo, vos modifications ont été enregistrées."
            subTitleText="...et elles sont déjà actives."
          />
        </div>
      )}
    </div>
  )
}
